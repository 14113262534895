import * as staticConfigs from './staticConfigs';
import i18n from './i18n';

export const DICT_TYPE_PAYERS = {
    dictType: i18n.t('dictionaries.payers.title'),
    dictItems: [
        { name: i18n.t('dictionaries.payers.InsuranceManagement'), routeLink: staticConfigs.ROUTE_INSURANCE_COMPANIES },
        { name: i18n.t('dictionaries.payers.PolicyTypes'), routeLink: staticConfigs.ROUTE_POLICY_TYPE },
        { name: i18n.t('dictionaries.payers.PayerTypes'), routeLink: staticConfigs.ROUTE_PAYER_TYPES },
        { name: i18n.t('dictionaries.codes.PaymentSource'), routeLink: staticConfigs.ROUTE_PAYMENT_SOURCE }
    ]
};

export const DICT_TYPE_CODES = {
    dictType: i18n.t('dictionaries.codes.title'),
    dictItems: [
        { name: i18n.t('dictionaries.codes.CPTCodes'), routeLink: staticConfigs.ROUTE_CPT_CODES },
        { name: i18n.t('dictionaries.codes.ICD10'), routeLink: staticConfigs.ROUTE_ICD10 },
        { name: i18n.t('dictionaries.codes.POSCODES'), routeLink: staticConfigs.ROUTE_POS_CODES },
        { name: i18n.t('dictionaries.codes.Modifiers'), routeLink: staticConfigs.ROUTE_MODIFIERS },
        { name: i18n.t('dictionaries.codes.RevenueCodes'), routeLink: staticConfigs.ROUTE_REVENUE_CODE },
        { name: i18n.t('dictionaries.codes.RemittanceRemarkCodes'), routeLink: staticConfigs.ROUTE_REMITTANCE_REMARK_CODE },
        { name: i18n.t('dictionaries.codes.AdjustmentReasonCodes'), routeLink: staticConfigs.ROUTE_ADJUSTMENT_REASON_CODE },
        { name: i18n.t('dictionaries.codes.FeeSchedule'), routeLink: staticConfigs.ROUTE_STANDARD_FEE_SCHEDULE_MANAGEMENT },
        { name: i18n.t('dictionaries.codes.FinancialClass'), routeLink: staticConfigs.ROUTE_FINANCIAL_CLASSESS },
        { name: i18n.t('dictionaries.codes.PayerClass'), routeLink: staticConfigs.ROUTE_PAYER_CLASSESS },
        { name: i18n.t('dictionaries.codes.DelayReasonCode'), routeLink: staticConfigs.ROUTE_DELAY_REASON_CODE },
        { name: i18n.t('dictionaries.codes.paymentAdjustmentType'), routeLink: staticConfigs.ROUTE_PAYMENT_ADJUSTMENT_TYPE },
    ]
};

export const DICT_TYPE_PATIENT_DEMOGRAPHICS = {
    dictType: i18n.t('dictionaries.patientDemographics.title'),
    dictItems: [
        // { name: i18n.t('dictionaries.patientDemographics.PatientType'), routeLink: staticConfigs.ROUTE_PATIENT_TYPE },
        { name: i18n.t('dictionaries.patientDemographics.PatientLanguages'), routeLink: staticConfigs.ROUTE_LANGUAGE_TYPE },
        { name: i18n.t('dictionaries.patientDemographics.Relationship'), routeLink: staticConfigs.ROUTE_RELATIONSHIP },
        { name: i18n.t('dictionaries.patientDemographics.ReferralSource'), routeLink: staticConfigs.ROUTE_REFERRAL_SOURCES }]
};

export const DICT_TYPE_BILLING = {
    dictType: i18n.t('dictionaries.billing.title'),
    dictItems: [
        { name: i18n.t('dictionaries.billing.AssignmentOfBenefits'), routeLink: staticConfigs.ROUTE_ASSIGNMENT_BENEFITS },
        { name: i18n.t('dictionaries.billing.AccidentType'), routeLink: staticConfigs.ROUTE_ACCIDENT_TYPES },
        { name: i18n.t('dictionaries.billing.ClaimFrequency'), routeLink: staticConfigs.ROUTE_CLAIM_FREQUENCY },
        { name: i18n.t('dictionaries.billing.ClaimStatus'), routeLink: staticConfigs.ROUTE_CLAIM_STATUSES },
        { name: i18n.t('dictionaries.billing.ClaimSubStatus'), routeLink: staticConfigs.ROUTE_CLAIM_SUB_STATUSES },
        { name: i18n.t('dictionaries.billing.ArStatus'), routeLink: staticConfigs.ROUTE_AR_STATUS },
        { name: i18n.t('dictionaries.billing.ARactionCode'), routeLink: staticConfigs.ROUTE_AR_ACTION_CODE },
        { name: i18n.t('dictionaries.billing.DocumentationMethod'), routeLink: staticConfigs.ROUTE_DOCUMENTATION_METHODS },
        { name: i18n.t('dictionaries.billing.DocumentType'), routeLink: staticConfigs.ROUTE_DOCUMENT_TYPES },
        { name: i18n.t('dictionaries.billing.ServiceAuthorizationException'), routeLink: staticConfigs.ROUTE_SERVICE_EXCEPTIONS },
        { name: i18n.t('dictionaries.billing.TransportReason'), routeLink: staticConfigs.ROUTE_TRANSPORT_REASONS },
        { name: i18n.t('dictionaries.billing.TrackingStatusType'), routeLink: staticConfigs.ROUTE_TRACKING_STATUS_TYPES },
        { name: i18n.t('dictionaries.billing.TrackingStatusSource'), routeLink: staticConfigs.ROUTE_TRACKING_STATUS_SOURCES },
        { name: i18n.t('dictionaries.billing.ServiceTypes'), routeLink: staticConfigs.ROUTE_SERVICE_TYPES },
        { name: i18n.t('dictionaries.billing.Taxonomies'), routeLink: staticConfigs.ROUTE_TAXONOMIES },
    ]
};

export const DICT_TYPE_APPOINTMENTS = {
    dictType: i18n.t('dictionaries.appointments.title'),
    dictItems: [
        { name: i18n.t('dictionaries.appointments.AppointmentStatus'), routeLink: staticConfigs.ROUTE_APPOINTMENT_STATUSES },
        { name: i18n.t('dictionaries.appointments.AppointmentType'), routeLink: staticConfigs.ROUTE_APPOINTMENT_TYPES }]
};

export const DICT_TYPE_GENERAL = {
    dictType: i18n.t('dictionaries.general.title'),
    dictItems: [
        // { name: i18n.t('dictionaries.general.TypeOfNotes'), routeLink: staticConfigs.ROUTE_TYPE_OF_NOTES },
        // { name: i18n.t('dictionaries.general.Providers'), routeLink: '' },
        { name: i18n.t('dictionaries.general.PracticeType'), routeLink: staticConfigs.ROUTE_PRACTICE_TYPES },
        { name: i18n.t('dictionaries.general.FacilityType'), routeLink: staticConfigs.ROUTE_FACILITY_TYPES }]
};

export const PRACTICE_DICT_TYPE_CODES = {
    dictType: i18n.t('dictionaries.codes.title'),
    dictItems: [
        { name: i18n.t('dictionaries.codes.FinancialClass'), routeLink: staticConfigs.ROUTE_PRACTICE_FINANCIAL_CLASSESS },
        { name: i18n.t('dictionaries.codes.PayerClass'), routeLink: staticConfigs.ROUTE_PRACTICE_PAYER_CLASSESS },
        { name: i18n.t('dictionaries.codes.CPTCodes'), routeLink: staticConfigs.ROUTE_PRACTICE_CPT_CODES },
        { name: i18n.t('dictionaries.codes.ICD10'), routeLink: staticConfigs.ROUTE_PRACTICE_ICD10 },
        { name: i18n.t('dictionaries.codes.RemittanceRemarkCodes'), routeLink: staticConfigs.ROUTE_PRACTICE_REMITTANCE_REMARK_CODE },
        { name: i18n.t('dictionaries.codes.AdjustmentReasonCodes'), routeLink: staticConfigs.ROUTE_PRACTICE_ADJUSTMENT_REASON_CODE },
    ]
};


export const PRACTICE_DICT_TYPE_PATIENT_DEMOGRAPHICS = {
    dictType: i18n.t('dictionaries.patientDemographics.title'),
    dictItems: [
        // { name: i18n.t('dictionaries.patientDemographics.PatientType'), routeLink: staticConfigs.ROUTE_PATIENT_TYPE },
        { name: i18n.t('dictionaries.patientDemographics.PatientLanguages'), routeLink: staticConfigs.ROUTE_PRACTICE_LANGUAGE_TYPE },
        { name: i18n.t('dictionaries.patientDemographics.ReferralSource'), routeLink: staticConfigs.ROUTE_PRACTICE_REFERRAL_SOURCES }]
};

export const PRACTICE_DICT_TYPE_GENERAL = {
    dictType: i18n.t('dictionaries.general.title'),
    dictItems: [
        // { name: i18n.t('dictionaries.general.TypeOfNotes'), routeLink: staticConfigs.ROUTE_TYPE_OF_NOTES },
        { name: i18n.t('dictionaries.general.ReferringProviderGroup'), routeLink: staticConfigs.ROUTE_PRACTICE_REFERRING_PROVIDER_GROUP }
    ]
};

export const PRACTICE_DICT_TYPE_BILLING = {
    dictType: i18n.t('dictionaries.billing.title'),
    dictItems: [
        { name: i18n.t('dictionaries.billing.ClaimStatus'), routeLink: staticConfigs.ROUTE_PRACTICE_CLAIM_STATUSES },
        { name: i18n.t('dictionaries.billing.ClaimSubStatus'), routeLink: staticConfigs.ROUTE_PRACTICE_CLAIM_SUB_STATUSES },
        { name: i18n.t('dictionaries.billing.ArStatus'), routeLink: staticConfigs.ROUTE_PRACTICE_AR_STATUS },
        { name: i18n.t('dictionaries.billing.ARactionCode'), routeLink: staticConfigs.ROUTE_PRACTICE_AR_ACTION_CODE },
        { name: i18n.t('dictionaries.billing.DocumentType'), routeLink: staticConfigs.ROUTE_PRACTICE_DOCUMENT_TYPES },
        { name: i18n.t('dictionaries.billing.ServiceAuthorizationException'), routeLink: staticConfigs.ROUTE_PRACTICE_SERVICE_EXCEPTIONS }]
};

export const PRACTICE_DICT_TYPE_APPOINTMENTS = {
    dictType: i18n.t('dictionaries.appointments.title'),
    dictItems: [
        { name: i18n.t('dictionaries.appointments.AppointmentStatus'), routeLink: staticConfigs.ROUTE_PRACTICE_APPOINTMENT_STATUSES },
        { name: i18n.t('dictionaries.appointments.AppointmentType'), routeLink: staticConfigs.ROUTE_PRACTICE_APPOINTMENT_TYPES }]
};

export const ALERT_TYPES = [{ "id": 1, "name": "Claim Alert" }, { "id": 2, "name": "Patient Alert" }, { "id": 3, "name": "Payment Alert" }]
export const CREDIT_CARD_TYPES = [{ "id": 1, "name": "Visa", "icon": "CCVisa" }, { "id": 2, "name": "Master", "icon": "CCMaster" }, { "id": 3, "name": "Discover", "icon": "CCDiscover" }, { "id": 4, "name": "Amex", "icon": "CCAmex" }]
export const CLAIM_SUBMISSION_TYPES = [{ "id": 1, "name": "Electronic" }, { "id": 2, "name": "Mail" }, { "id": 3, "name": "Fax" }]
export const CLAIM_TYPES = [{ "id": 1, "name": "Professional" }, { "id": 2, "name": "Institutional" }, { "id": 3, "name": "Dental" }, { "id": 4, "name": "Invoice" }]
export const EMPLOYMENT_STATUS = [{ "id": 1, "name": "Full Time" }, { "id": 2, "name": "Part Time" }, { "id": 3, "name": "Not Employed" }, { "id": 4, "name": "Self Employed" }, { "id": 5, "name": "Retired" }, { "id": 6, "name": "Active Military" }, { "id": 7, "name": "Disabled" }, { "id": 8, "name": "Unknown" }]
export const LIVE_MODES = [{ "id": 1, "name": "Test" }, { "id": 2, "name": "Live" }]
export const SPECIAL_PROGRAM_CODES = [{ "id": 1, "name": "Special Federal Funding" }, { "id": 2, "name": "Disability" }, { "id": 3, "name": "Second Opinion/Sergery" }, { "id": 4, "name": "Physically Challenged Children's Program" }, { "id": 5, "name": "Family Planning" }]
export const ELIGIBILITY_DEDUCTIBLES = [{ "id": 1, "name": "In-Network" }, { "id": 2, "name": "Out of Network" }]
export const ELIGIBILITY_OUT_OF_POCKETS = [{ "id": 1, "name": "In-Network" }, { "id": 2, "name": "Out of Network" }]
export const CREATE_CLAIMS = [{ "id": 1, "name": "Institutional" }, { "id": 2, "name": "Professional" }, { "id": 3, "name": "Dental" }, { "id": 4, "name": "Invoice" }]
export const TRANSPORT_CODE = [{ "id": 1, "name": "Initial Trip" }, { "id": 2, "name": "Return Trip" }, { "id": 3, "name": "Round Trip" }, { "id": 4, "name": "Transfer Trip" }]

export const DICT_TYPE_CONSTANTS_COL1 = {
    dictType: i18n.t('dictionaries.constants.title'),
    dictItems: [
        { name: i18n.t('dictionaries.constants.AlertType'), routeLink: '', subItems: ALERT_TYPES },
        { name: i18n.t('dictionaries.constants.CreditCardTypes'), routeLink: '', subItems: CREDIT_CARD_TYPES },
        { name: i18n.t('dictionaries.constants.ClaimSubmissionType'), routeLink: '', subItems: CLAIM_SUBMISSION_TYPES },
        { name: i18n.t('dictionaries.constants.ClaimType'), routeLink: '', subItems: CLAIM_TYPES },
        { name: i18n.t('dictionaries.constants.EmploymentStatus'), routeLink: '', subItems: EMPLOYMENT_STATUS },
        { name: i18n.t('dictionaries.constants.LiveMode'), routeLink: '', subItems: LIVE_MODES },
        { name: i18n.t('dictionaries.constants.SpecialProgramCode'), routeLink: '', subItems: SPECIAL_PROGRAM_CODES },
        { name: i18n.t('dictionaries.constants.EligibilityDeductible'), routeLink: '', subItems: ELIGIBILITY_DEDUCTIBLES },
        { name: i18n.t('dictionaries.constants.EligibilityOutOfPocket'), routeLink: '', subItems: ELIGIBILITY_OUT_OF_POCKETS },
        { name: i18n.t('dictionaries.constants.CreateClaim'), routeLink: '', subItems: CREATE_CLAIMS },
        { name: i18n.t('dictionaries.constants.TransportCode'), routeLink: '', subItems: TRANSPORT_CODE },
    ]
};
export const GENDERS = [{ "id": 1, "name": "Male" }, { "id": 2, "name": "Female" }, { "id": 3, "name": "Transgender" }]
export const ETHNICITIES = [{ "id": 1, "name": "Non Hispanic" }, { "id": 2, "name": "Hispanic" }, { "id": 3, "name": "Other" }, { "id": 4, "name": "Unknown" }]
export const INSURANCE_SEQUENCES = [{ "id": 1, "name": "Primary" }, { "id": 2, "name": "Secondary" }, { "id": 3, "name": "Tertiary" }, { "id": 4, "name": "Selfpay" }]
export const MARITAL_STATUS = [{ "id": 1, "name": "Married" }, { "id": 2, "name": "Unmarried" }, { "id": 3, "name": "Divorced" }, { "id": 4, "name": "Widowed" }, { "id": 5, "name": "Unknown" }]
export const PAYEMNT_MODES = [{ "id": 1, "name": "Cash" }, { "id": 2, "name": "Check" }, { "id": 3, "name": "Credit Cards" }]
export const PAYMENT_TYPES = [{ "id": 1, "name": "Weekly" }, { "id": 2, "name": "Monthly" }, { "id": 3, "name": "Querterly" }, { "id": 4, "name": "Half-yearly" }, { "id": 5, "name": "Yearly" }]
export const POST_PAYMENT_PRIMARY_TYPES = [{ "id": 1, "name": "Patient Payment" }, { "id": 2, "name": "Insurance Payment" }]
export const POST_PAYMENT_SECONDARY_TYPES = [{ "id": 1, "name": "Co-Pay" }, { "id": 2, "name": "Payment" }]
export const POST_PAYMENT_SECONDARY_SUB_TYPES=[{ "id": 1, "name": "Coinsurance" }, { "id": 2, "name": "Deductible" }, { "id": 3, "name": "Payment- Other" }]
export const PRIORITIES = [{ "id": 1, "name": "Primary" }, { "id": 2, "name": "Secondary" }, { "id": 3, "name": "Tertiary" }]
export const CLAIM_DELAY_REASONS = [{ "id": 1, "name": "Administration Delay In Prior Auth Process" }, { "id": 2, "name": "Authorization Delays" }, { "id": 3, "name": "Delay in Certifying Provider" }, { "id": 4, "name": "Delay in Delivery of Custom-made Appliances" }, { "id": 5, "name": "Delay in Determining the Eligibility Information" }, { "id": 6, "name": "Delay in Supplying Billing Forms" }, { "id": 7, "name": "Litigation" }, { "id": 8, "name": "Original Claim Rejected or Denied due Unrelated to Billing Limitation Rules" }, { "id": 9, "name": "Other" }, { "id": 10, "name": "Proof of Timely Filling Unavailable" }, { "id": 11, "name": "Third Party Processing Delay" }]
export const AUTHORIZATION_REFERRAL = [{ "id": 1, "name": "Prior Authorization" }, { "id": 2, "name": "Referral" }]
export const CLAIM_FREQUENCIES = [{ "id": 1, "name": "01-Original Claim" }, { "id": 2, "name": "07-Replacement of Prior Claim" }, { "id": 3, "name": "08-Cancel/Void of Prior Claim" }]
export const ICD = [{ "id": 1, "name": "ICD9" }, { "id": 2, "name": "ICD10" }]
export const claimAmountType=[{"id":"Equal","name":"Equal"},{"id":"LessThan","name":"Less Than"},{"id":"LessThanEqual","name":"Less Than or Equal"},{"id":"GreaterThan","name":"Greater Than"},{"id":"GreaterThanEqual","name":"Greater Than or Equal"},{"id":"Between","name":"Between"}]
export const ClaimPrintType=[{ "id": 1, "name": "Professional" }, { "id": 2, "name": "Institutional" },{ "id": 3, "name": "Dental" }, { "id": 4, "name": "Invoice" }]
export const DICT_TYPE_CONSTANTS_COL2 = {
    dictType: " ",
    dictItems: [
        { name: i18n.t('dictionaries.constants.Gender'), routeLink: '', subItems: GENDERS },
        { name: i18n.t('dictionaries.constants.Ethnicity'), routeLink: '', subItems: ETHNICITIES },
        { name: i18n.t('dictionaries.constants.InsuranceSequence'), routeLink: '', subItems: INSURANCE_SEQUENCES },
        { name: i18n.t('dictionaries.constants.MaritalStatus'), routeLink: '', subItems: MARITAL_STATUS },
        { name: i18n.t('dictionaries.constants.ModeOfPayment'), routeLink: '', subItems: PAYEMNT_MODES },
        { name: i18n.t('dictionaries.constants.PaymentType'), routeLink: '', subItems: PAYMENT_TYPES },
        { name: i18n.t('dictionaries.constants.ClaimDelayReason'), routeLink: '', subItems: CLAIM_DELAY_REASONS },
        { name: i18n.t('dictionaries.constants.AuthorizationReferral'), routeLink: '', subItems: AUTHORIZATION_REFERRAL },
        { name: i18n.t('dictionaries.constants.ClaimFrequency'), routeLink: '', subItems: CLAIM_FREQUENCIES },
        { name: i18n.t('dictionaries.constants.ICD'), routeLink: '', subItems: ICD },
    ]
};

export const BILL_UNDER = [{ "id": 1, "name": "Individual" }, { "id": 2, "name": "Group" }]
export const RACES = [{ "id": 1, "name": "Native American" }, { "id": 2, "name": "White" }, { "id": 3, "name": "Asian" }, { "id": 4, "name": "Black" }, { "id": 5, "name": "Other" }, { "id": 6, "name": "Unknown" }]
export const RESIDENT_TYPES = [{ "id": 1, "name": "Private Home" }, { "id": 2, "name": "Nursing Home" }, { "id": 3, "name": "Skilled Nursing Home" }, { "id": 4, "name": "Homeless" }, { "id": 5, "name": "Unknown" }]
export const STUDENT_STATUS = [{ "id": 1, "name": "Not A Student" }, { "id": 2, "name": "Full Time Student" }, { "id": 3, "name": "Part Time Student" }, { "id": 4, "name": "Unknown" }]
export const SWITCH_PRACTICE_SEARCH = [{ "id": 1, "name": "Practice" }, { "id": 2, "name": "Patient" }, { "id": 3, "name": "Claim" }, { "id": 4, "name": "Payment" }, { "id": 5, "name": "Guarantor" }, { "id": 6, "name": "Provider" }]
export const DRUG_UNITS = [{ "id": 1, "name": "UN-Units" }, { "id": 2, "name": "GR-Gram" }, { "id": 3, "name": "ML-Milliliter" }, { "id": 4, "name": "F2-International" }, { "id": 5, "name": "ME-Milligram" }]
export const TYPE_OF_LETTERS = [{ "id": 1, "name": "COB Updation Notification" }, { "id": 2, "name": "Policy Related Letters" }, { "id": 3, "name": "Overdue Notification" }, { "id": 4, "name": "ABN Change" }, { "id": 5, "name": "Consent Change" }, { "id": 6, "name": "Custom Letter" }]
export const TYPE_OF_NOTES = [{ "id": 1, "name": "Billing Notes" }, { "id": 2, "name": "Patient Notes" }, { "id": 3, "name": "Payment Notes" }, { "id": 4, "name": "Provider Notes" },]
export const PATIENT_TYPES = [{ "id": 1, "name": "Insurance Patient" }, { "id": 2, "name": "Self-Pay Patient" }, { "id": 3, "name": "Workers's Compensation" }, { "id": 4, "name": "Liability Insurance" },]
export const SELECTED_PATIENT_DROPDOWN = [{ "id": 1, "name": "Patient" }, { "id": 2, "name": "Patient & Claims" }]
export const NUMBER_OF_STATEMENTS = [{ "id": 1, "name": "Add to sent statement count" }, { "id": 2, "name": "Not add to sent statement count" }]

export const DICT_TYPE_CONSTANTS_COL3 = {
    dictType: " ",
    dictItems: [
        { name: i18n.t('dictionaries.constants.ProviderTypeBillUnder'), routeLink: '', subItems: BILL_UNDER },
        { name: i18n.t('dictionaries.constants.Race'), routeLink: '', subItems: RACES },
        { name: i18n.t('dictionaries.constants.ResidentType'), routeLink: '', subItems: RESIDENT_TYPES },
        { name: i18n.t('dictionaries.constants.StudentStatus'), routeLink: '', subItems: STUDENT_STATUS },
        { name: i18n.t('dictionaries.constants.SwitchPracticeSearchBy'), routeLink: '', subItems: SWITCH_PRACTICE_SEARCH },
        { name: i18n.t('dictionaries.constants.DrugUnit'), routeLink: '', subItems: DRUG_UNITS },
        { name: i18n.t('dictionaries.constants.TypeOfLetters'), routeLink: '', subItems: TYPE_OF_LETTERS },
        { name: i18n.t('dictionaries.constants.TypeOfNotes'), routeLink: '', subItems: TYPE_OF_NOTES },
        { name: i18n.t('dictionaries.constants.PatientTypes'), routeLink: '', subItems: PATIENT_TYPES },

    ]
};


export const DICT_TYPE_CONSTANTS_COL4 = {
    dictType: " ",
    dictItems: [
        { name: i18n.t('dictionaries.constants.EligibilityDeductible'), routeLink: '', subItems: ELIGIBILITY_DEDUCTIBLES },
        { name: i18n.t('dictionaries.constants.EligibilityOutOfPocket'), routeLink: '', subItems: ELIGIBILITY_OUT_OF_POCKETS },
        { name: i18n.t('dictionaries.constants.CreateClaim'), routeLink: '', subItems: CREATE_CLAIMS },
        { name: i18n.t('dictionaries.constants.AuthorizationReferral'), routeLink: '', subItems: AUTHORIZATION_REFERRAL },
        { name: i18n.t('dictionaries.constants.ClaimFrequency'), routeLink: '', subItems: CLAIM_FREQUENCIES },
        { name: i18n.t('dictionaries.constants.ICD'), routeLink: '', subItems: ICD },
        { name: i18n.t('dictionaries.constants.DrugUnit'), routeLink: '', subItems: DRUG_UNITS },
    ]
};

export const PROVIDER_TYPES = [{ "id": 1, "name": "Individual" }, { "id": 2, "name": "Group" }]
export const PROVIDER_TYPE_INDIVIDUAL = 1;
export const PROVIDER_TYPE_ORGANIZATION = 2;

export const YesOrNo = [{ id: 1, name: "Yes" }, { id: 2, name: "No" }]

export const MEASUREMENT_UNITS = [{ id: 1, name: "Hemoglobin" }, { id: 2, name: "Hematocrit" }, { id: 3, name: "Epoetin" }, { id: 4, name: "Creatin" }]
export const DataForICD = [{ id: 1, name: "ICD 9" }, { id: 2, name: "ICD 10" }];
export const defaultIdForICD = 2;
export const defaultIdForYes = 1;
export const defaultIdForNo = 2;
export const EmgList = [{ id: 2, name: "N" }, { id: 1, name: "Y" }];
export const PAYER_TYPE_LIST = [{ 'id': 1, 'name': 'Primary' }, { 'id': 2, 'name': 'Secondary' }, { 'id': 3, 'name': 'Tertiary' }, { 'id': 4, 'name': 'Patient' }];
export const ACTIVE_STATUS = [{ 'id': "1", 'name': 'Active' }, { 'id': "2", 'name': 'Inactive' }, { 'id': "3", 'name': 'All' }];
export const INSURANCEPAYMENTMODES = [{ 'id': 1, 'name': 'Check' }, { 'id': 2, 'name': 'EFT' }, { 'id': 3, 'name': 'Credit Card' }, { id: 4, 'name': 'NON' }];
export const ERA_PAYMENT_MODE = [
    { 'id': 'CHK', 'name': 'Check' },
    { 'id': 'ACH', 'name': 'EFT' },
    { 'id': 'BOP', 'name': 'Financial Institution Option' },
    { 'id': 'FWT', 'name': 'Federal Reserve Funds' },
    { 'id': 'NON', 'name': 'Non-Payment' }]
export const PAGE_LOCKS = [{ id: 'patient', name: "Patient ID" }, { id: 'claim', name: 'Claim ID' }];
export const RESPONSIBILITY_TYPES = [{ id: 1, name: 'Primary' }, { id: 2, name: 'Secondary' }, { id: 3, name: 'Tertiary' }, { id: 4, 'name': 'Patient' }, { id: 5, 'name': 'Paid' }];
export const PROFESSIONAL_CLAIMS = [{ id: 1, name: 'Enrollment Not Required' }, { id: 2, name: 'Enrollment Required' }, { id: 3, name: 'Not Available' }];
export const INSTITUTIONAL_CLAIMS = [{ id: 1, name: 'Enrollment Not Required' }, { id: 2, name: 'Enrollment Required' }, { id: 3, name: 'Not Available' }];
export const REMITS = [{ id: 1, name: 'Enrollment Not Required' }, { id: 2, name: 'Enrollment Required' }, { id: 3, name: 'Not Available' }];
export const ELIGILBILITY = [{ id: 1, name: 'Enrollment Not Required' }, { id: 2, name: 'Enrollment Required' }, { id: 3, name: 'Not Available' }];
export const CLAIMS_MONITORING = [{ id: 1, name: 'Enrollment Not Required' }, { id: 2, name: 'Enrollment Required' }, { id: 3, name: 'Not Available' }];
export const ESTIMATION = [{ id: 1, name: 'Enrollment Not Required' }, { id: 2, name: 'Enrollment Required' }, { id: 3, name: 'Not Available' }];
export const SECONDARY_CLAIM = [{ id: 1, name: 'Paper' }, { id: 2, name: 'Electronic' }];
export const CLAIMS_ATTACHMENTS = [{ id: 1, name: 'Claim Attachments Not Available' }, { id: 2, name: 'Claim Attachments Supported' }];
export const SUBMISSION_MODE = [{ id: 1, name: 'Send Electronic Claims Via Clearninghouse' }, { id: 2, name: 'Send Claims to Clearninghouse to Print and Mail' }, { id: 3, name: 'Do not Send the Claims to Clearninghouse' }];
export const NETWORK_STATUS = [{ id: 1, name: 'In-Network' }, { id: 2, name: 'Out Of Network' }];
export const SELECT_OPTION_ALL = { "id": "all", "name": "All" };
export const PRINT_SELECTED_OPTION = [{ id: 1, name: 'Add to sent statement count' }, { id: 2, name: 'Not add to sent statement count' }];

export const TASK_STATUS = [
    { "id": 1, "name": "Not Started" },
    { "id": 2, "name": "In Progress" },
    { "id": 3, "name": "On Hold" },
    { "id": 4, "name": "Complete" },
];

export const ASSIGNED_TYPE = [
    { "id": "1", "name": "Assigned To Me" },
    { "id": "2", "name": "Assigned By Me" },
];
export const TASK_PRIORITIES = [
    { "id": 1, "name": "Normal" },
    { "id": 2, "name": "Low" },
    { "id": 3, "name": "High" },
];
export const LINK_TYPE = [
    { "id": 1, "name": "Claim" },
    { "id": 2, "name": "Patient" },
    // {"id": 3, "name": "Payer"},
];
export const LINK_TYPE_CLAIM_ONLY = [
    { "id": 1, "name": "Claim" },
];
export const NOTES_TYPES = [
    { "id": 1, "name": 'Appointment Notes' },
    { "id": 2, "name": 'Claim Notes' },
    { "id": 3, "name": 'Patient Notes' },
    { "id": 4, "name": 'Payment Notes' },
    { "id": 5, "name": 'Provider Notes' }
]
export const NOTES_CLAIM_TYPES = [
    { "id": 2, "name": 'Claim Notes' }
]

export const TYPE_OF_MESSAGE = [
    { "id": 1, "name": 'Claims (DFT)' },
    { "id": 2, "name": 'Patients (ADT)' },
    { "id": 3, "name": 'Appointments (SIU)' },
]

export const STATUS_OF_MESSAGE = [
    { "id": 1, "name": 'Warning' },
    { "id": 2, "name": 'Success' },
    { "id": 3, "name": 'Error' },
]

export const SHOW_TYPE = [
    { "id": 1, "name": 'Unapplied' },
    { "id": 2, "name": 'Applied' },
]

export const CHARGE_TYPE = [
    { "id": 1, "name": 'Default' },
    { "id": 2, "name": 'Custom' },
]

export const DEFAULT_CLAIM_ACCIDENT_TYPE = "Not related to Accident";