import React from 'react';
import PropTypes from 'prop-types';
import i18n from "../../../utilities/i18n";
import SelectInput from "../../commons/input/SelectInput";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import RangeInput from "../../commons/input/RangeInput";

/**
 * A specialized filter component for handling balance amount inputs
 * Supports single amount and range inputs based on selected type
 * 
 * @component
 * @param {Object} props
 * @param {Object} props.commonProps - Common props for the select input
 * @param {Object} props.dropdownData - Available dropdown options
 * @param {Object} props.filterValues - Current filter values
 * @param {Function} props.handleValueChange - Callback for value changes
 * @param {string} props.label - Label for the filter
 */
const BalanceAmountFilter = ({ commonProps, dropdownData, filterValues, handleValueChange, label }) => {
    return (
        <div id="report-filter-balance-amnt-totals" className="row">
            <div className={!filterValues.balanceAmountType ? "col-12" : "col-6"}>
                <SelectInput
                    {...commonProps}
                    data={dropdownData || []}
                    onValueChange={(e) => handleValueChange(e.target.value, commonProps.name)}
                />
            </div>
            {filterValues.balanceAmountType && (
                <div className="col-6">
                    {filterValues.balanceAmountType !== "Between" ? (
                        <CurrencyInputs
                            id="report-filter-balance-amnt-from"
                            name="balanceAmountTotalsFrom"
                            value={filterValues.balanceAmountTotalsFrom?.trim().replace(/[^0-9.]/g, "") || ''}
                            onValueChange={(e) => handleValueChange(e.target.value, 'balanceAmountTotalsFrom')}
                            label={`${label} ${filterValues.balanceAmountType}`}
                            placeholder={i18n.t("reports.filters.dollar")}
                        />
                    ) : (
                        <RangeInput
                            name1="balanceAmountTotalsFrom"
                            name2="balanceAmountTotalsTo"
                            value1={filterValues.balanceAmountTotalsFrom?.trim().replace(/[^0-9.]/g, "") || ''}
                            value2={filterValues.balanceAmountTotalsTo?.trim().replace(/[^0-9.]/g, "") || ''}
                            onValueChange1={(e) => handleValueChange(e.target.value, 'balanceAmountTotalsFrom')}
                            onValueChange2={(e) => handleValueChange(e.target.value, 'balanceAmountTotalsTo')}
                            placeholder={i18n.t("reports.filters.dollar")}
                            label={`${label} ${filterValues.balanceAmountType}`}
                            ValidationMsg={i18n.t("commons.balanceError")}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

BalanceAmountFilter.propTypes = {
    commonProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.any
    }).isRequired,
    dropdownData: PropTypes.object.isRequired,
    filterValues: PropTypes.shape({
        balanceAmountType: PropTypes.string,
        balanceAmountTotalsFrom: PropTypes.string,
        balanceAmountTotalsTo: PropTypes.string
    }).isRequired,
    handleValueChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

export default BalanceAmountFilter; 