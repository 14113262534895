import React, { useState, useEffect, useContext } from 'react';
import i18n from "../../../utilities/i18n";
import { format } from 'date-fns';
import service from "./service";
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanelIndivdualPatient';
import PatientLedgerFilter from '../../patientManagement/patientLedger/patientLedgerFilter';
import { currencyFormat } from '../../../utilities/commonUtilities';
import { PATIENT_STATEMENT_VENDORS } from '../../../utilities/staticConfigs';
import LoadingContext from '../../../container/loadingContext';


function PatientAccountSummary(props) {
  const [insuranceSummary, setInsuranceSummary] = useState('');
  const [patientSummary, setPatientSummary] = useState('');
  const startDate=format(new Date(new Date() - (90 * 86400000)), 'yyyy-MM-dd');
  const endDate=format(new Date(), 'yyyy-MM-dd');
  const [paymentSummary, setPaymentSummary] = useState('');
  const setShowLoadingBar=useContext(LoadingContext)

  const [advancedQuery, setAdvancedQuery] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);

  function GetPatientAgingSummary() {
    setShowLoadingBar(true);
    const result = service.GetPatientAgingSummaryDetails(props.patientPK);
    result.then((response) => {
      setShowLoadingBar(false);
      if (response?.data?.insurance_datas) {
          setInsuranceSummary(response?.data?.insurance_datas)
      }
      if (response?.data?.patient_datas) {
          setPatientSummary(response?.data?.patient_datas)
      }
    }).catch(()=>{
      setShowLoadingBar(false);
    })
  }

  function GetPatientPaymentSummary() {
    setShowLoadingBar(true);
    const result = service.GetPatientPaymentSummaryDetails(props.patientPK, startDate, endDate, advancedQuery);
    result.then((response) => {
      setShowLoadingBar(false);
      setPaymentSummary(response.data);
    }).catch(()=>{
      setShowLoadingBar(false);
    });
  }

  useEffect(() => {
    if(props.patientPK){
    GetPatientAgingSummary();
    GetPatientPaymentSummary();
    }
  }, [props.patientPK]);

  useEffect(() => {
    if (searchFilter) {
      GetPatientPaymentSummary();
      setSearchFilter(false);
    }
  }, [searchFilter])

  return (
    <React.Fragment>
      <div className="row">
        <div className="padding-left17 div-inline">
          <label className="margin-top-3px mb-3">{i18n.t("patientaccountsummary.agingBalance")}</label>
        </div>
      </div>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>{i18n.t("patientaccountsummary.respobsibleType")}</th>
              <th>{i18n.t("patientaccountsummary.unbilled")}</th>
              <th>{i18n.t("patientaccountsummary.current")}</th>
              <th>31-60</th>
              <th>61-90</th>
              <th>91-120</th>
              <th>120+</th>
              <th>{i18n.t("patientaccountsummary.total")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{i18n.t("patientaccountsummary.insurance")}</td>
              <td align="right">{currencyFormat(insuranceSummary.unbilled)}</td>
              <td align="right">{currencyFormat(insuranceSummary.current)}</td>
              <td align="right">{currencyFormat(insuranceSummary._31_60)} </td>
              <td align="right">{currencyFormat(insuranceSummary._61_90)} </td>
              <td align="right">{currencyFormat(insuranceSummary._91_120)}</td>
              <td align="right">{currencyFormat(insuranceSummary.above_120) }</td>
              <td align="right">{currencyFormat(insuranceSummary.total)}</td>
            </tr>
            <tr>
              <td>{i18n.t("patientaccountsummary.patient")}</td>
              <td align="right">{currencyFormat(patientSummary.unbilled)} </td>
              <td align="right">{currencyFormat(patientSummary.current)}</td>
              <td align="right">{currencyFormat(patientSummary._31_60)}</td>
              <td align="right">{currencyFormat(patientSummary._61_90)}</td>
              <td align="right">{currencyFormat(patientSummary._91_120)}</td>
              <td align="right">{currencyFormat(patientSummary.above_120)}</td>
              <td align="right">{currencyFormat(patientSummary.total)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="form_patient_info" className='mb-3 mt-2'>
        < RightSidePanel title={i18n.t("patientaccountsummary.filterBy")} onclickLabel={i18n.t("patientaccountsummary.filterBy")} >
          <PatientLedgerFilter setAdvancedQuery={setAdvancedQuery} setSearchFilter={setSearchFilter}>
          </PatientLedgerFilter>
        </ RightSidePanel>
      </div>
      <div className="row">
        <div className="col-3">
          <table className="table">
            <tbody>
              <tr>
                <td>{i18n.t("patientaccountsummary.totalCharges")}</td>
                <td align="right">{currencyFormat(paymentSummary.charges)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.totalPayments")}</td>
                <td align="right">{currencyFormat (paymentSummary.payments)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.totalAdjustments")}</td>
                <td align="right">{currencyFormat (paymentSummary.adjustments)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.totalBalance")}</td>
                <td align="right">{currencyFormat (paymentSummary.balance)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.insuranceBalance")}</td>
                <td align="right">{currencyFormat (paymentSummary.insurance_balance)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.patientBalance")}</td>
                <td align="right">{currencyFormat (paymentSummary.patient_balance)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.insuranceCredits")}</td>
                <td align="right">{currencyFormat (paymentSummary.insurance_credits)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.patientCredits")}</td>
                <td align="right">{currencyFormat(paymentSummary.patient_credits)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-5">
          <table className="table">
            <tbody>
              <tr>
                <td>{i18n.t("patientaccountsummary.totalClaimsBilled")}</td>
                <td>{paymentSummary.claims_billed}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.totalProceduresBilled")}</td>
                <td>{paymentSummary.procedures_billed}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.averagePaymentReceivedPerClaims")}</td>
                <td align='right'>{currencyFormat(paymentSummary.avg_paid_claims)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.averagePaymentReceivedPerProcedures")}</td>
                <td align='right'>{currencyFormat(paymentSummary.avg_paid_procedures)}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.grossCollectionRate")}</td>
                <td>{paymentSummary.gross_rate} %</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.netCollectionRate")}</td>
                <td>{paymentSummary.net_rate} %</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.ARDays")}</td>
                <td>{paymentSummary.ar_days}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.firstPassRate")}</td>
                <td>{paymentSummary.first_pass_rate}</td>
              </tr>
              <tr>
                <td>{i18n.t("patientaccountsummary.denialPercentage")}</td>
                <td>{paymentSummary.denial}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-4">
          <table className="table">
            <thead>
              <tr>
                <th>{i18n.t("patientaccountsummary.vendor")}</th>
                <th>{i18n.t("patientaccountsummary.count")}</th>
                <th>{i18n.t("patientaccountsummary.lastStatementSent")}</th>
              </tr>
            </thead>
            <tbody>
              {paymentSummary.patient_statement_release_info?.map((info, index) => {
                const vendorName = PATIENT_STATEMENT_VENDORS.find(v => v.id.toString() === info.vendor)?.name || 'Unknown';
                return (
                  <tr key={index}>
                    <td>{vendorName}</td>
                    <td className="text-right">{info.count}</td>
                    <td className="text-right">{info.last_statement_sent}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment >
  );
}
export default PatientAccountSummary;
