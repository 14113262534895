import api from '../../../service/api'

const GetListARActionCode = async (pageSize, page, isAdminModule, practicePK, searchValue) => {
    let path = null;
    if (isAdminModule === 'false')
        path = 'practice/ar-action/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    else
        path = 'super-admin/ar-action/?page_size=' + pageSize + '&page=' + page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }
    return api.get(path);
}

const AddARActionCode = async (data, isAdminModule) => {
    let path = null;
    if (isAdminModule === 'false') {
        path = 'practice/ar-action/';
    } else {
        path = 'super-admin/ar-action/'
    }
    return api.post(path, data)
}

const GetARActionCode = async (id,isAdminModule) => {
    let path = ""
    if (isAdminModule === 'false') {
        path = 'practice/ar-action/'+ id;
    } else {
        path = 'super-admin/ar-action/'+ id;
    }
    return api.get(path);
}

const DeleteARActionCode = async (id,isAdminModule) => {
    let path = ""
    if (isAdminModule === 'false') {
        path = 'practice/ar-action/'+ id;
    } else {
        path = 'super-admin/ar-action/'+ id;
    }
    return api.destroy(path);
}

const UpdateARActionCode = async (id, data,isAdminModule) => {
    let path = ""
    if (isAdminModule === 'false') {
        path = 'practice/ar-action/'+ id;
    } else {
        path = 'super-admin/ar-action/'+ id;
    }
    return api.put(path, data);
}

export default { GetListARActionCode, AddARActionCode, GetARActionCode, DeleteARActionCode, UpdateARActionCode }