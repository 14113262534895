import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from '../service';
import { getStorage } from "../../../utilities/browserStorage";
import { AGING_LIST, TRANSACTION_TYPES } from "../constants";
import { claimAmountType } from "../../../utilities/dictionaryConstants";

// Initial State
const initialState = {
    isLoading: false,
    isErrorGettingDropdownData: false,
    isFilterDropDownDataReceived: false,
    renderingProviderList: [],
    referringProviderList: [],
    insuranceCompanyList: [],
    praticeUserList: [],
    serviceLocationList: [],
    cptList: [],
    icdList: [],
    payerTypes: [],
    claimStatusList: [],
    claimSubStatusList: [],
    agingList: AGING_LIST,
    transactionTypes: TRANSACTION_TYPES,
    balanceAmountTypes: claimAmountType
};


/*
 * Function to get call the api for all the dropdown list data 
 */
export const getDropDownListData = createAsyncThunk(
    "report/getDropDownListData",
    async (requestedLists = [], thunkAPI) => {
        const pageSize = 0;
        const page = 0;
        const practicePK = getStorage('practice');
        const type = 'dropdown';

        // Validate inputs
        if (!practicePK) {
            return thunkAPI.rejectWithValue({
                errorMessage: 'Practice ID is required',
                error: 'MISSING_PRACTICE_ID'
            });
        }

        if (!Array.isArray(requestedLists) || requestedLists.length === 0) {
            return {};
        }

        const currentState = thunkAPI.getState().filterDropdownData;

        // Map of API calls for each list type
        const apiMap = {
            icdList: () => service.GetICD10List(pageSize, page, practicePK, type)
                .then(response => response.data),
            payerTypes: () => service.GetPayerTypes()
                .then(response => response.data.results),
            referringProviderList: () => service.ListReferringProviders(pageSize, page, practicePK)
                .then(response => response.data),
            insuranceCompanyList: () => service.getInsuranceCompany(pageSize, page, practicePK)
                .then(response => response.data),
            praticeUserList: () => service.praticeUserList(pageSize, page, practicePK)
                .then(response => response.data),
            serviceLocationList: () => service.ServiceLocationLists(practicePK)
                .then(response => {
                    return response.data.map(item => ({
                        ...item,
                        id: `${item.Id}||${item.location_type}`
                    }));
                }),
            renderingProviderList: () => service.ListRenderingProviders(pageSize, page, practicePK)
                .then(response => response.data),
            cptList: () => service.GetCPTCodeList(pageSize, page, practicePK, type)
                .then(response => response.data),
            claimStatusList: () => service.ListClaimStatus(pageSize, page, practicePK)
                .then(response => response.data),
            claimSubStatusList: () => service.ListClaimSubStatus(pageSize, page, practicePK)
                .then(response => response.data)
        };

        try {
            // Separate lists into those that need fetching and those that don't
            const { listsToFetch, existingData } = requestedLists.reduce((acc, key) => {
                if (!(key in apiMap)) return acc; // Skip invalid keys

                const currentData = currentState[key];
                if (!currentData || !currentData.length) {
                    acc.listsToFetch.push(key);
                } else {
                    acc.existingData[key] = currentData;
                }
                return acc;
            }, { listsToFetch: [], existingData: {} });

            if (listsToFetch.length === 0) {
                return existingData;
            }

            // Track success/failure for each API call
            const apiResults = await Promise.allSettled(
                listsToFetch.map(async key => {
                    try {
                        const data = await apiMap[key]();
                        return { key, status: 'fulfilled', data };
                    } catch (error) {
                        return {
                            key,
                            status: 'rejected',
                            error: {
                                message: error.message,
                                code: error.code,
                                status: error.response?.status
                            }
                        };
                    }
                })
            );

            // Process results and collect errors
            const newData = {};
            const errors = [];

            apiResults.forEach(result => {
                const { key } = result.value;
                if (result.value.status === 'fulfilled') {
                    newData[key] = result.value.data;
                } else {
                    errors.push({
                        key,
                        ...result.value.error
                    });
                    // Use existing data if available, empty array as fallback
                    newData[key] = currentState[key] || [];
                }
            });

            // Log errors if any occurred
            if (errors.length > 0) {
                console.error('Some dropdown lists failed to load:', errors);
            }

            return {
                ...existingData,
                ...newData
            };

        } catch (error) {
            console.error('Critical error in getDropDownListData:', error);
            return thunkAPI.rejectWithValue({
                errorMessage: 'Failed to fetch dropdown data',
                error: error?.message,
                code: error?.code,
                status: error?.response?.status
            });
        }
    }
);

const reportSlice = createSlice({
    name: "reportFilters",
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDropDownListData.pending, (state) => {
                state.isLoading = true;
                state.isErrorGettingDropdownData = false;
            })
            .addCase(getDropDownListData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isErrorGettingDropdownData = false;
                state.isFilterDropDownDataReceived = true
                const {
                    icdList,
                    payerTypes,
                    referringProviderList,
                    insuranceCompanyList,
                    praticeUserList,
                    serviceLocationList,
                    renderingProviderList,
                    cptList,
                    claimStatusList,
                    claimSubStatusList,
                } = action.payload;
                state.icdList = icdList;
                state.payerTypes = payerTypes;
                state.referringProviderList = referringProviderList;
                state.insuranceCompanyList = insuranceCompanyList;
                state.praticeUserList = praticeUserList?.map((item) => ({ ...item, id: item.user_id })) ?? [];
                state.serviceLocationList = serviceLocationList;
                state.renderingProviderList = renderingProviderList;
                state.cptList = cptList;
                state.claimStatusList = claimStatusList;
                state.claimSubStatusList = claimSubStatusList;
            })
            .addCase(getDropDownListData.rejected, (state) => {
                state.isLoading = false;
                state.isErrorGettingDropdownData = true;
            });
    },
});

export const { onResetData } = reportSlice.actions
export default reportSlice.reducer;