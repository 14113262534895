import React, { useContext, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import './style.css';
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import service from './service';
import headerComponentService from '../../mainLayoutComponent/headerComponent/service';
import Notify from '../../commons/notify';
import { getStorage } from '../../../utilities/browserStorage';
import * as staticConfigs from '../../../utilities/staticConfigs';
import PasswordStrengthCheck from './PasswordStrengthCheck';
import LoadingContext from '../../../container/loadingContext';  
import { useDispatch } from 'react-redux';
import { clearIndexedDb } from '../../../utilities/commonUtilities';


const PasswordManagement  = (props) => {
    let loginRoute = staticConfigs.LOGIN_ROUTE;
    const setShowLoadingBar = useContext(LoadingContext)
    const changeType = props.type;
    const history = useHistory();
    const dispatch = useDispatch();
    const practicePK = getStorage('practice');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordClass, setNewPasswordClass] = useState('');
    const [confirmPasswordClass, setConfirmPasswordClass] = useState('');
    const [passwordError, setpasswordError] = useState('');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const fToken = params.get('f_token');
    const userId = params.get('user_id');
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [loginRedirect, setLoginRedirect] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(null)

    const validatePasswords = () => {
        if (newPassword.trim() === '') {
            setNewPasswordClass('input-error');
            return false; 
        }
        else if (confirmPassword.trim() === '') {
            setConfirmPasswordClass('input-error');
            return false;
        }
        else if (newPassword.trim() !== confirmPassword.trim()) {
            setConfirmPasswordClass('input-error');
            showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_matching_error'));
            return false;
        }
        else if (changeType === 'reset' && oldPassword.trim() === newPassword.trim()){
            showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_oldnew_same_error'));
            return false;
        } else if (passwordStrength.pStrength < 4) {
            return false
        }
        else {
            return true; 
        }
    }

    const resetAllReduxStates = () => {
        dispatch({ type: 'chargesDebit/onResetData' });
        dispatch({ type: 'paymentAdjustment/onResetData' });
        dispatch({ type: 'openArReport/onResetData' });
        dispatch({ type: 'reportFilters/reset' });
        dispatch({ type: 'transactionReport/onResetData' });
        dispatch({ type: 'statementMgmnt/resetData' });
        dispatch({ type: 'patientInfo/setInitialState' });
        dispatch({ type: 'claimManagement/resetClaimtoInitalState' });
        dispatch({ type: 'claimSaveManagement/setClaimSavetoInitialState' });
        dispatch({ type: 'arClaims/ResetData' });
        dispatch({ type: 'ClaimFilter/reset' });
        dispatch({ type: 'batchEligibility/onResetData' });
        dispatch({ type: 'chatbox/reset' });
        dispatch({ type: 'commonStates/reset' });
        dispatch({ type: 'claimManagement/resetToInitial' });
        dispatch({ type: 'DropDownOptionSlice/resetToInitial' });
    };

    function createFormData(data) {
        const formData = new FormData();
        /**
         * appending key and key value to formData
         */
        for (const key in data) {
            formData.append(key, data[key]);
        }

        return formData;
    }

    function onSaveFormData(e) {
        e.preventDefault();
        setShowLoadingBar(true);
        if(!validatePasswords()) {
            setShowLoadingBar(false);
            return false;
        }
        else {             
            if (changeType === 'forgot'){
                const formData = {
                    'fToken': fToken,
                    'userId': userId,
                    'new_password': newPassword,
                    'confirm_password': confirmPassword
                };

                const data = createFormData(formData)

                let result = service.ResetPassword(data);
                result.then(async (response) => {
                    if (response.status === 200) {
                        showNotifyWindow('show', 'success', i18n.t('errorMessages.password_updated'));
                        localStorage.clear();
                        await clearIndexedDb();
                        
                        resetAllReduxStates(); // Reset all the Redux-states before routing
                        history.push(staticConfigs.LOGIN_ROUTE);
                    } else if (response.data.message === 'old_password') {
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_old_error'));
                    } else if (response.data.message === 'new_password') {
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_matching_error'));
                    } else if (response.data.message === 'oldnew_same_password') {
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_oldnew_same_error'));
                    }
                    setShowLoadingBar(false);
                });
            } else if (changeType === 'reset') {
                const formData = {
                    'new_password': newPassword,
                    'confirm_password': confirmPassword,
                    'old_password': oldPassword,
                    ...(practicePK && { 'practice_pk': practicePK })
                };
                const data = createFormData(formData)

                let result = service.ChangePassword(data);
                result.then( async (response) => {
                    if (response.status === 200) {
                        showNotifyWindow('show', 'success', i18n.t('errorMessages.password_updated'));
                        setTimeout(() => {
                            headerComponentService.LogOut().then(response => {
                                    if(response && response.data && response.data.status === 'success'){
                                        setLoginRedirect(true);
                                    }
                                });
                        }, 1000);
                        await clearIndexedDb();
                    } else if (response.data.message === 'old_password') {
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_old_error'));
                    } else if (response.data.message === 'new_password') {
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_matching_error'));
                    } else if (response.data.message === 'oldnew_same_password') {
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_oldnew_same_error'));
                    }
                    setShowLoadingBar(false);
                });
            } else if (changeType === 'force-reset') {
                const formData = {
                    'userId': getStorage('userID'),
                    'old_password': oldPassword,
                    'new_password': newPassword,
                    'confirm_password': confirmPassword
                };
                const data = createFormData(formData)

                let result = service.ForceChangePassword(data);
                result.then(async (response) => {
                    if (response.status === 200) {
                        showNotifyWindow('show', 'success', i18n.t('errorMessages.password_updated'));
                        localStorage.removeItem("passwordExpired");
                        localStorage.removeItem("userID");
                        await clearIndexedDb();
                        setLoginRedirect(true);
                    } else if(response.data.message ===  'old_password'){
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_old_error'));
                    } else if(response.data.message  === 'new_password'){
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_matching_error'));
                    } else if(response.data.message  === 'oldnew_same_password'){
                        showNotifyWindow('show', 'danger', i18n.t('errorMessages.password_oldnew_same_error'));
                    }
                    setShowLoadingBar(false);
                });
            }
        }
    }

    function onHandleChange(e) {
        const name = e.target.name;
        let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
        if (name === 'old_password') setOldPassword(value);
        else if(name === 'new_password') setNewPassword(value);
        else if (name === 'confirm_password') setConfirmPassword(value);
        setpasswordError('');
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    if (loginRedirect)
        return <Redirect to={{pathname: loginRoute}}/>

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <form id="form_dataEntry" autoComplete="off" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data" >
                    <div className="login-form-header padding-top20 text-center" >{props.header}</div>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="input-group">
                            {(changeType === 'reset' || changeType === 'force-reset') &&
                                <div className="form-group padding-top10">
                                    <TextInput type="password" id="old_password" name="old_password" label={i18n.t('changePassword.form.lable.password')} onValueChange={onHandleChange} className={newPasswordClass} required={true} value={oldPassword} />
                                </div>
                            }
                                <div className="form-group padding-top10">
                                    <TextInput type="password" id="new_password" name="new_password" label={i18n.t('changePassword.form.lable.newPassword')} onValueChange={onHandleChange} className={newPasswordClass}  required={true} value={newPassword}/>
                                </div>

                            <PasswordStrengthCheck inputValue={newPassword} passwordStrength={passwordStrength} setPasswordStrength={setPasswordStrength} />

                                <div className="form-group padding-top10" >
                                    <TextInput type="password" id="confirm_password" name="confirm_password" label={i18n.t('changePassword.form.lable.confirmPassword')} onValueChange={onHandleChange} className={confirmPasswordClass} required={true} value={confirmPassword}/>
                                </div> 
                            {passwordError.length > 1 &&
                                (
                                    <>
                                        <div className="text-right text-red padding-bottom5">{passwordError} </div>

                                    </>
                                )
                            }
                                <div className="form-group text-center">
                                    <button className="btn btn-primary-blue" style={{width:'100%'}}>{i18n.t("commons.save")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
        </React.Fragment>
    )
}

export default PasswordManagement