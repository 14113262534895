import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';

import patientService from '../patients/service';
import SelectInput from '../../commons/input/SelectInput';
import CalendarInput from '../../commons/input/CalendarInput';

import service1 from '../../userManagement/practices/service';
import LoadingContext from '../../../container/loadingContext';
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { filterDateValues } from '../../../utilities/dateFilterCalculation';
import { DEFAULT_PAGING_SIZE, CLAIMDATEOFSERVICERANGE, DATE_FILTER_IDS } from '../../../utilities/staticConfigs';
import Label from '../../commons/Label';

const PatientLedgerFilter = (props) => {
    const setShowLoadingBar = useContext(LoadingContext);
    const [insuranceValue, setInsuranceValue] = useState([]);

    const practicePK = getStorage("practice") ? parseInt(getStorage("practice")) : '';
    const [practiceLocations, setPracticeLocations] = useState([]);
    const [facility, setFacility] = useState([]);
    const [renderingProvider, setRenderingProvider] = useState([]);
    const [referingProvider, setReferingProvider] = useState([]);
    const [dos1, setDos1] = useState("");
    const [dos2, setDos2] = useState("");
    const [lastPaymentDate1, setLastPaymentDate1] = useState("");
    const [lastPaymentDate2, setLastPaymentDate2] = useState("");

    const [multiInsurances, setMultiInsurances] = useState([]);
    const [multiPracticeLocations, setMultiPracticeLocations] = useState([]);
    const [multiFacilities, setMultiFacilities] = useState([]);
    const [multiProviders, setMultiProviders] = useState([]);
    const [multiReferingProviders, setMultiReferingProviders] = useState([]);

    //Pagination start
    const [activePage, setActivePage] = useState(1);

    const [dateTypeDos, setDateTypeDos] = useState(DATE_FILTER_IDS.custom);
    const [dateTypePay, setDateTypePay] = useState(DATE_FILTER_IDS.custom);

    function onHandleDateType(selValue, dateType) {
        if (dateType === 'DOS') {
            setDateTypeDos(selValue.target.value);
            let dateObj = filterDateValues(selValue.target.value);
            setDos1(dateObj.fromDate);
            setDos2(dateObj.toDate);
        }
        else if (dateType === 'PAY') {
            setDateTypePay(selValue.target.value);
            let dateObj = filterDateValues(selValue.target.value);
            setLastPaymentDate1(dateObj.fromDate);
            setLastPaymentDate2(dateObj.toDate);
        }
    }

    function onHandleChangeDos1(selected) {
        setDos1(selected)
    }

    function onHandleChangeDos2(selected) {
        setDos2(selected)
    }

    function onHandleLastPaymentDate1(selected) {
        setLastPaymentDate1(selected)
    }

    function onHandleLastPaymentDate2(selected) {
        setLastPaymentDate2(selected)
    }

    function getInsuranceCompaniesData(pageSize) {
        setShowLoadingBar(true);
        let pageNum = 0;
        const result = patientService.ListInsuranceNames(pageSize, pageNum, practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data)
                setMultiInsurances(response.data);
        });
    }

    function getPracticeLocations(pageSize) {
        setShowLoadingBar(true);
        let pageNum = 0;
        const result = service1.ListPracticeLocations(pageSize, pageNum, practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data) {
                setMultiPracticeLocations(response.data);
            }
        });
    }

    function getReferringProviders(pageSize) {
        let pageNum = 0;
        const result = patientService.ListReferringProviders(pageSize, pageNum, practicePK);
        result.then(response => {
            if (response.data)
                setMultiReferingProviders(response.data);
        });
    }

    function getRenderingProviders(pageSize) {
        setShowLoadingBar(true);
        let pageNum = 0;
        const result = patientService.ListRenderingProviders(pageSize, pageNum, practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data)
                setMultiProviders(response.data);
        });
    }

    function getFacilityGroupData(pageSize) {
        setShowLoadingBar(true);
        let pageNum = 0
        const result = patientService.ListFacilities(pageSize, pageNum, practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data)
                setMultiFacilities(response.data);
        });
    }

    useEffect(() => {
        getReferringProviders(DEFAULT_PAGING_SIZE, activePage);
        getRenderingProviders(DEFAULT_PAGING_SIZE, activePage);
        getFacilityGroupData(DEFAULT_PAGING_SIZE, activePage);
        getPracticeLocations(DEFAULT_PAGING_SIZE, activePage);
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, activePage);
    }, []);

    function onAdvanceSearchPatients() {
        if (props.setShowAdvancedSearchTab) {
            props.setShowAdvancedSearchTab(false);
        }

        const query = buildQuery();
        props.setAdvancedQuery(query);
        props.setSearchFilter(true);
        hideRightContent();

        function buildQuery() {
            let query = '&practice_pk=' + practicePK + '&filter=1';

            query += buildQueryFor('insurances', insuranceValue);
            query += buildQueryFor('practice_locations', practiceLocations);
            query += buildQueryFor('facilities', facility);
            query += buildQueryFor('rendering_providers', renderingProvider);
            query += buildQueryFor('referring_providers', referingProvider);
            query += buildQueryForDate('dos_from', dos1);
            query += buildQueryForDate('dos_to', dos2);
            query += buildQueryForDate('last_payment_date_from', lastPaymentDate1);
            query += buildQueryForDate('last_payment_date_to', lastPaymentDate2);

            return query;
        }

        function buildQueryFor(param, value) {
            if (value.length > 0) {
                return `&${param}=${JSON.stringify(value)}`;
            }

            return '';
        }

        function buildQueryForDate(param, value) {
            try {
                const formattedDate = format(value, 'yyyy-MM-dd');
                return `&${param}=${formattedDate}`;
            } catch {
                return '';
            }
        }

        function hideRightContent() {
            const rightContent = document.getElementById("right-content-individual-p");
            rightContent.classList.remove('active');
        }
    }


    function resetAdvancedSearch() {
        setShowLoadingBar(true);
        setDateTypeDos(DATE_FILTER_IDS.custom)
        setDateTypePay(DATE_FILTER_IDS.custom)
        setDos1('');
        setDos2('');
        setLastPaymentDate1('');
        setLastPaymentDate2('');
        setActivePage(1);
        setInsuranceValue([]);
        setPracticeLocations([]);
        setFacility([]);
        setRenderingProvider([]);
        setReferingProvider([]);
        setShowLoadingBar(false);
    }

    function onClearSearch() {
        resetAdvancedSearch();
    }

    function MaterialMultiSelectHandle(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "insuranceValue") {
            setInsuranceValue(value);
        }
        else if (name === "practiceLocation") {
            setPracticeLocations(value);
        }
        else if (name === "facility") {
            setFacility(value);
        }
        else if (name === "renderingProvider") {
            setRenderingProvider(value);
        }
        else if (name === "referingProvider") {
            setReferingProvider(value);
        }
    }

    function closeAdvancedSearchPage() {
        document.getElementById('right-content-individual-p')?.classList.remove('active');
    }

    return (
        <React.Fragment>
            <div className="pb-3">
                <MaterialMultiselect name={"insuranceValue"} id='insuranceValue' value={insuranceValue} onValueChange={MaterialMultiSelectHandle}
                    options={multiInsurances} label={i18n.t("patientPages.patients.labelInsurance")} />
            </div>
            <div className="pb-3">
                <MaterialMultiselect name="practiceLocation" id='practiceLocation' value={practiceLocations} onValueChange={MaterialMultiSelectHandle}
                    options={multiPracticeLocations} label={i18n.t("patientPages.patients.labelClaimPracticeLocation")}
                />
            </div>
            <div className="pb-3">
                <MaterialMultiselect name="facility" id='facility' value={facility} onValueChange={MaterialMultiSelectHandle}
                    options={multiFacilities} label={i18n.t("patientPages.patients.labelClaimFacility")}
                />
            </div>
            <div className="pb-3">
                <MaterialMultiselect name="renderingProvider" id='renderingProvider' value={renderingProvider} onValueChange={MaterialMultiSelectHandle}
                    options={multiProviders} label={i18n.t("patientPages.patients.RenderingProvider")}
                />
            </div>
            <div className="pb-3">
                <MaterialMultiselect name="referingProvider" id='referingProvider' value={referingProvider} onValueChange={MaterialMultiSelectHandle}
                    options={multiReferingProviders} label={i18n.t("patientPages.patients.referingProvider")}
                />
            </div>
            <Label label={i18n.t("patientPages.patients.lastClaimdosBetween")} />

            <div className='row'>
                <div className='col-12'>
                    <SelectInput id='date-type-dos' data={CLAIMDATEOFSERVICERANGE} name='dateTypeDos' value={dateTypeDos} onValueChange={(e) => onHandleDateType(e, 'DOS')} className={"date-filter-select"} selectOptionRemove={true} />
                </div>
            </div>

            <div className='row'>
                <div className='col-6'>
                    <CalendarInput name="dos1" id='dos-1' selected={dos1} onValueChange={onHandleChangeDos1} placeholder="From" />
                </div>
                <div className='col-6'>
                    <CalendarInput name="dos2" id='dos-2' selected={dos2} onValueChange={onHandleChangeDos2} placeholder="To" minDate={dos1} />
                </div>
            </div>
            
            <Label label={i18n.t("patientPages.patients.lastPaymentDate")} />

            <div className='row'>
                <div className='col-12'>
                    <SelectInput data={CLAIMDATEOFSERVICERANGE} id='date-type-pay' name='dateTypePay' value={dateTypePay} onValueChange={(e) => onHandleDateType(e, 'PAY')} className={"date-filter-select"} selectOptionRemove={true} />
                </div>
            </div>
            
            <div className='row'>
                <div className='col-6'>
                    <CalendarInput name="lastPaymentDate1" id='lastPayment-date1' selected={lastPaymentDate1} onValueChange={onHandleLastPaymentDate1} placeholder="From" />
                </div>
                <div className='col-6'>
                    <CalendarInput name="lastPaymentDate2" id='lastPayment-date2' selected={lastPaymentDate2} onValueChange={onHandleLastPaymentDate2} placeholder="To" minDate={lastPaymentDate1} />
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <button type="button" onClick={closeAdvancedSearchPage} className="btn btn-secondary btn-common-height35 mb-1 mr-2 px-4">
                    {i18n.t("commons.cancel")}
                </button>
                <div className="justify-right  mb-4">
                    <button type='button' className="btn btn-secondary btn-common-height35 mr-3 px-4" onClick={onClearSearch}>{i18n.t("commons.clear")}</button>
                    <button type="button" onClick={() => onAdvanceSearchPatients(DEFAULT_PAGING_SIZE, activePage)} className="btn btn-primary-blue btn-common-height35">{i18n.t("patientPages.patients.Search")}</button>
                </div>
            </div>
        </React.Fragment >
    )
}
export default PatientLedgerFilter;