import api from "../../../service/api";

const ARStatusList =  async(pageSize, page,isAdminModule,practicePK, searchValue) => {
    let path = null;
    if (isAdminModule === 'false') {
        path = 'practice/ar-status/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
    } else {
        path = `super-admin/ar-status/?page_size=${pageSize}&page=${page}`;
    }
    if (searchValue) {
        path += `&search=${searchValue}`
    }

    return api.get(path)
}

const AddARStatusData = async(data,isAdminModule) => {
    let path = null;
    if (isAdminModule === 'false'){
        path = 'practice/ar-status/';
    } else {
        path = 'super-admin/ar-status/';
    }

    return api.post(path,data)
}

const ARactionCodeList = async(pageSize, page,search,isAdminModule,practicePK)=> {
    let path = null;

    if (isAdminModule === 'false') {
            path = `practice/ar-action/?page_size=${pageSize}&page=${page}&search=${search}&practice_pk=${practicePK}`
    } else {
            path = `super-admin/ar-action/?page_size=${pageSize}&page=${page}&search=${search}`
    }
    
    return api.get(path);
}

const GetARStatusData = async(ARStatusId,isAdminModule) => {
    let path = null;
    if (isAdminModule === 'false') {
        path = `practice/ar-status/${ARStatusId}`;
    } else {
        path = `super-admin/ar-status/${ARStatusId}`;
    }
    return api.get(path)
}

const UpdateARStatusData = (ARStatusId,data,isAdminModule,) => {
    let path = null;
    if (isAdminModule === 'false') {
        path = `practice/ar-status/${ARStatusId}`;
    } else  {
        path = `super-admin/ar-status/${ARStatusId}`;
    }

    return api.put(path,data)
}

const DeleteARStatusData = (ARStatusId,isAdminModule) => {
    let path = null;
    if (isAdminModule === 'false') {
        path = `practice/ar-status/${ARStatusId}`
    } else {
        path = `super-admin/ar-status/${ARStatusId}`

    }

    return api.destroy(path)
}

export default { ARStatusList, AddARStatusData, ARactionCodeList, GetARStatusData, UpdateARStatusData, DeleteARStatusData }