import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { DateTime } from 'luxon';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Components
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from '../../commons/input/input';
import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";
import SelectInput from "../../commons/input/SelectInput";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import CommonButton from '../../commons/Buttons';
import Notify from '../../commons/notify';
import MultiSearchSelect from "../../commons/AutoCompleteSearch";
import RangeInput from "../../commons/input/RangeInput";
import BalanceAmountFilter from './BalanceAmountFilter';

// Assets & Utils
import i18n from "../../../utilities/i18n";

import { getDropDownListData } from "./reportFilterSlice";
import './style.css';

/**
 * @typedef {Object} FilterConfig
 * @property {string} fieldName - Unique identifier for the filter
 * @property {string} label - Display label for the filter
 * @property {('dateField'|'multiSelect'|'multiSearchSelect'|'select'|'text'|'currency'|'range')} type - Type of filter input
 * @property {string} [dropDownOptionName] - Name of the dropdown options array (for select-type filters)
 * @property {string} minDateField - Field to set as minDate
 */

/**
 * ReportFilter Component - A comprehensive filter interface for report generation
 * 
 * @component
 * @param {Object} props
 * @param {FilterConfig[]} props.filterConfig - Array of filter configurations
 * @param {Object} props.filterValues - Current values of all filters
 * @param {Function} props.filterValueSetter - Callback to update filter values
 * @param {Function} props.onReset - Callback to reset all filters
 * @param {Function} props.onSearch - Callback to trigger search with current filters
 * @param {string} props.reportType - Type of report being filtered
 * 
 * @example
 * const filterConfig = [{
 *   fieldName: 'dateRange',
 *   label: 'Date Range',
 *   type: 'dateField'
 * }];
 * 
 * return (
 *   <ReportFilter
 *     filterConfig={filterConfig}
 *     filterValues={values}
 *     filterValueSetter={setValues}
 *     onReset={handleReset}
 *     onSearch={handleSearch}
 *     reportType="transactions"
 *   />
 * );
 */

/**
 * Maximum column width for basic filters section
 * Calculation:
 * - Bootstrap grid has 12 columns per row
 * - We want to show 3 rows of filters in the basic section
 * - 12 columns/row × 3 rows = 36 total columns
 * - Any filters beyond 36 columns will be moved to advanced section
 * @constant {number}
 */
const MAX_BOOTSTRAP_COL_WIDTH_TO_SPLIT_FILTER = 24;

/**
 * Split filters into basic and advanced based on column count
 * @param {FilterConfig[]} filters - Array of filter configurations
 * @returns {[FilterConfig[], FilterConfig[]]} Tuple of [basicFilters, advancedFilters]
 */
const splitFilters = (filters) => {
    let columnCount = 0;
    let splitIndex = 0;

    for (let i = 0; i < filters.length; i++) {
        const columnWidth = parseInt(filters[i].columnWidth?.replace('col-', '') || '3');
        if (columnCount + columnWidth > MAX_BOOTSTRAP_COL_WIDTH_TO_SPLIT_FILTER) {
            break;
        }
        columnCount += columnWidth;
        splitIndex = i + 1;
    }

    return [
        filters.slice(0, splitIndex),
        filters.slice(splitIndex)
    ];
};

const ReportFilter = ({ filterConfig, filterValues, filterValueSetter, onReset, onSearch }) => {
    const [hideAdvanceFilter, setHideAdvanceFilter] = useState(false);
    const [notification, setNotification] = useState({
        show: 'hide',
        type: 'success',
        description: ''
    });

    const dispatch = useDispatch();
    const dropdownData = useSelector((state) => state.filterDropdownData);

    useEffect(() => {
        const requiredDropDownList = filterConfig.filter(item => item.dropDownOptionName).map(item => item.dropDownOptionName);
        dispatch(getDropDownListData(requiredDropDownList));
    }, []);

    useEffect(() => {
        if (dropdownData.isErrorGettingDropdownData) {
            showNotification("error", 'Error fetching drop-down lists, please contact Admin!');
        }
    }, [dropdownData.isErrorGettingDropdownData]);

    /**
     * Displays a notification message with auto-hide functionality
     * 
     * @param {('success'|'error'|'warning'|'info')} type - Type of notification
     * @param {string} message - Message to display
     * @param {number} [duration=3000] - Duration in milliseconds to show notification
     */
    const showNotification = (type, message, duration = 3000) => {
        setNotification({
            show: 'show',
            type,
            description: message
        });
        setTimeout(() => {
            setNotification(prev => ({ ...prev, show: 'hide' }));
        }, duration);
    };

    /**
     * Handles value changes for all filter inputs
     * 
     * @param {*} value - New value from the input
     * @param {string} fieldName - Key identifying the filter in state
     * @param {('input'|'date'|'multiSearchSelect'|'multiSelect')} [type='input'] - Type of input
     */
    const handleValueChange = (value, fieldName, type = 'input') => {
        try {
            let serializedValue = value;

            if (type === 'date') {
                if (value instanceof Date && !isNaN(value)) {
                    // Convert Date to Luxon DateTime and format
                    serializedValue = DateTime.fromJSDate(value).toFormat('yyyy-MM-dd');
                } else {
                    serializedValue = null;
                }
            }

            filterValueSetter({
                ...filterValues,
                [fieldName]: serializedValue
            });
        } catch (error) {
            console.error('Error handling value change:', error);
            filterValueSetter({
                ...filterValues,
                [fieldName]: ''
            });
        }
    };

    /**
     * Renders the appropriate input component based on filter configuration
     * 
     * @param {FilterConfig} config - Configuration for the filter
     * @param {number} index - Index of the filter in the list
     * @returns {React.ReactElement|null} Rendered filter input component
     */
    const renderFilterInput = (config) => {
        const { type, fieldName, label, dropDownOptionName, minDateField } = config;
        const commonProps = {
            id: `report-filter-${fieldName}`,
            name: fieldName,
            label: label,
            value: filterValues[fieldName],
        };

        if (filterValues.balanceAmountType != "") {
            const element = document.getElementById("report-filter-balance-amnt-totals");
            if (element && element.parentElement) {
                element.parentElement.classList.remove('col-3');
                element.parentElement.classList.add('col-6');
            }
        } else {
            const element = document.getElementById("report-filter-balance-amnt-totals");
            if (element && element.parentElement) {
                element.parentElement.classList.remove('col-6');
                element.parentElement.classList.add('col-3');
            }
        }

        // Special case for balance amount totals
        if (fieldName === 'balanceAmountType') {
            return (
                <BalanceAmountFilter
                    commonProps={commonProps}
                    dropdownData={dropdownData[dropDownOptionName]}
                    filterValues={filterValues}
                    handleValueChange={handleValueChange}
                    label={label}
                />
            );
        }

        switch (type) {
            case 'dateField': {
                let selectedDate = filterValues[fieldName]
                    ? DateTime.fromFormat(filterValues[fieldName], 'yyyy-MM-dd').toJSDate()
                    : null;

                const currentDate = DateTime.now().toJSDate();

                return (
                    <CalendarInput
                        {...commonProps}
                        minDate={filterValues[minDateField] ? new Date(filterValues[minDateField]) : undefined}
                        maxDate={currentDate}
                        selected={selectedDate}
                        onValueChange={(date) => handleValueChange(date, fieldName, 'date')}
                    />
                );
            }

            case 'multiSearchSelect':
                return (
                    <MultiSearchSelect
                        {...commonProps}
                        options={dropdownData[dropDownOptionName] || []}
                        onChange={(value) => handleValueChange(value, fieldName, 'multiSearchSelect')}
                    />
                );

            case 'multiSelect':
                return (
                    <MaterialMultiselect
                        {...commonProps}
                        options={dropdownData[dropDownOptionName] || []}
                        onValueChange={(e) => handleValueChange(e.target.value, fieldName, 'multiSelect')}
                    />
                );

            case 'select':
                return (
                    <SelectInput
                        {...commonProps}
                        data={dropdownData[dropDownOptionName] || []}
                        onValueChange={(e) => handleValueChange(e.target.value, fieldName)}
                    />
                );

            case 'text':
                return (
                    <TextInput
                        {...commonProps}
                        type="text"
                        onValueChange={(e) => handleValueChange(e.target.value, fieldName)}
                    />
                );

            case 'currency':
                return (
                    <CurrencyInputs
                        {...commonProps}
                        onValueChange={(e) => handleValueChange(e.target.value, fieldName)}
                    />
                );

            case 'range':
                return (
                    <RangeInput
                        {...commonProps}
                        onValueChange1={(e) => handleValueChange(e.target.value, `${fieldName}From`)}
                        onValueChange2={(e) => handleValueChange(e.target.value, `${fieldName}To`)}
                    />
                );

            default:
                return null;
        }
    };

    /**
     * Renders a section of filters with proper layout
     * 
     * @param {FilterConfig[]} filters - Array of filter configurations to render
     * @param {boolean} [isAdvanced=false] - Whether this is the advanced filters section
     * @returns {React.ReactElement} Rendered filter section
     */
    const renderFilterSection = (filters, isAdvanced = false) => {
        const filterItems = filters.map((config, index) => (
            <div key={config.fieldName} className={`${config.columnWidth ?? 'col-3'}`}>
                {renderFilterInput(config, index)}
            </div>
        ));

        return isAdvanced ? (
            <div className={`row advanced-filter-transition ${isAdvanced ? '' : 'hide'}`}>
                {filterItems}
            </div>
        ) : (
            <>
                {filterItems}
            </>
        );
    };

    const [basicFilters, advancedFilters] = splitFilters(filterConfig);

    return (
        <div>
            <Notify {...notification} />
            <TransitionGroup>
                <CSSTransition
                    key="report-filter-component"
                    timeout={700}
                    classNames="fade"
                    appear
                    exit
                    enter={false}
                >
                    <Form autoComplete="off" className="searchBox pl-4 pb-5 pr-4">
                        <div className="row">
                            {renderFilterSection(basicFilters)}
                            {hideAdvanceFilter && renderFilterSection(advancedFilters, true)}
                        </div>

                        {/* Only show advanced filters button if there are advanced filters */}
                        <div className={`action-buttons-container ${hideAdvanceFilter ? 'with-advanced' : ''}`}>
                            <div className="button-group">
                                {advancedFilters.length > 0 && (
                                    <CommonButton
                                        onClick={() => setHideAdvanceFilter(!hideAdvanceFilter)}
                                        variant="outlined"
                                        label={i18n.t("reports.title.advancedFilters")}
                                        endIcon={hideAdvanceFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        iconStyle={{
                                            fontSize: '20px',
                                            marginLeft: '5px'
                                        }}
                                    />
                                )}
                                <CommonButton
                                    onClick={onReset}
                                    variant="outlined"
                                    label={i18n.t("reports.title.reset")}
                                    className="action-btn"
                                />
                                <CommonButton
                                    onClick={onSearch}
                                    variant="contained"
                                    label={i18n.t("reports.title.search")}
                                    className="action-btn"
                                />
                            </div>
                        </div>
                    </Form>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

ReportFilter.propTypes = {
    filterConfig: PropTypes.arrayOf(PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['dateField', 'multiSelect', 'multiSearchSelect', 'select', 'text', 'currency', 'range']).isRequired,
        dropDownOptionName: PropTypes.string
    })).isRequired,
    filterValues: PropTypes.object.isRequired,
    filterValueSetter: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    reportType: PropTypes.string.isRequired
};

export default ReportFilter;