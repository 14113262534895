import React, { useContext, useEffect, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom';
import service from "./service";
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n'
import TableComponent from '../../commons/tableComponent/tableComponent'
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import { getStorage } from '../../../utilities/browserStorage';
import Notify from '../../commons/notify';
import { ADD_ERROR, ADD_SUCCESS, DELETE_SUCCESS, UPDATE_ERROR, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import actionImg from '../../../assets/images/action.png';
import CalendarInput from '../../commons/input/CalendarInput';
import { format } from 'date-fns';
import Moment from 'react-moment';
import TextAreaInput from '../../commons/textarea/textarea';
import claimService from '../SearchClaims/service';
import { MAX_OPEN_TABS_CLAIMS, ROUTE_SEARCH_CLAIMS, ROUTE_VIEW_PAYMENTS } from '../../../utilities/staticConfigs';
// import notes from "../../../assets/images/AR Icons/notes.png";
// import rebill from "../../../assets/images/AR Icons/rebill.png";
// import assign from "../../../assets/images/AR Icons/assign.png";
// import postpayment from "../../../assets/images/AR Icons/postpayment.png";
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
// import ImageIconRoundButton from '../../commons/ImageIconRoundButton';
import { mainHeadings, subHedaings1, subHedaings2 } from './ARClaimsTableConstant';
import { permission_key_values_claim, permission_key_values_payments } from '../../../utilities/permissions';
import { CommonDateFormat, checkPermission } from '../../../utilities/commonUtilities';
import IconButtons from './IconButtons';
import { Menu, MenuItem } from '@mui/material';
import SelectInput from '../../commons/input/SelectInput';


function ARClaimTab(props) {
    const history = useHistory();
    const practicePK = getStorage("practice");
    const [activeRowId, setActiveRowId] = useState(0);
    const [header, setHeader] = useState("");
    const setShowLoadingBar = useContext(LoadingContext);
    const noRecordsFound = i18n.t("commons.noRecords");
    const [tabClaimData, setTabClaimData] = useState({});

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [redirectToClaim, setRedirectToClaim] = useState(false);

    // assignTask model window
    const [assignClaimUsersList, setAssignClaimUsersList] = useState([]);
    const [deleteProviderName, setDeleteProviderName] = useState("");
    const [deleteProviderId, setDeleteProviderId] = useState("");
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [claimActive, setClaimActive] = useState(true);
    const [showAssignModal, setShowAssignModal] = useState(false);
    // notse modal
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [notesModalVariables, setNotesModalVariables] = useState({
        "next_follow_up_date": '',
        "notes": '',
        "category": 'ar_claim',
        "ar_status_id": '',
        "ar_action": '',
    });
    const [notesModalList, setNotesModalList] = useState([]);

    const [gotoURL, setGotoURL] = useState(false);
    const [URLPath, setURLPath] = useState('');
    const [selectedID, setSelectedID] = useState('');
    
    const [ARStatusCodeList, setARStatusCodeList] = useState([]);
    const [ARActionCodeList, setARActionCodeList] = useState([])

    const notesModalReset = () => {
        setNotesModalVariables({
            "next_follow_up_date": '',
            "notes": '',
            "ar_status_id" : '',
            "ar_action": ''
        });
    }

    // const [claimTableData, setClaimTableData] = useState([]);
    const [mainDatas, setMainDatas] = useState([
    ]);
    const [subDatas1, setSubDatas1] = useState([
    ])
    const [subDatas2, setSubDatas2] = useState([
    ]);

    let logCodeRouteIds = {
        payment_payment: ROUTE_VIEW_PAYMENTS,
    }
    let logCodeRoutes = {
        // 
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    // SOME CODES JUST NEED TO BE HIGHLIGHTED ADD THEM HERE (NO CLICK AND REDIRECTION)
    let logCodeHighLight = {
    }

    function resetForm() {
        setHeader("");
    }
    function assignClaim() {
        setHeader(i18n.t("arClaims.assignClaimTab.header"));
        setShowAssignModal(true);
    }
    useEffect(() => {
        getARClaim(props.claimPK);
        getAssignClaimList();
        getTabClaimData(props.claimPK);
    }, [practicePK, props.claimPK]);

    useEffect(() => {
        if (showNotesModal) {
            getARClaimNotes(props.claimPK);
        }
    }, [showNotesModal]);
    useEffect(() => {
        getAssignClaimList();
    }, [practicePK, props.claimPK]);

    useEffect(() => {
        arStatusNotesList()
        arActionNoteList()
    },[])

    const arStatusNotesList = async() => {
        try {
            let result = await service.ARStatusNoteList(0,0,practicePK);
            let structuredData =   result.data.map((item)=> ({
                id: item.id,
                name: item.name
            }))

            setARStatusCodeList(structuredData || []);
        } catch (error) {
            console.error("Error while fetching AR status list:", error);
        }
    }

    const arActionNoteList = async() => {
        try{
            let result = await service.ARActionNoteList(0,0,practicePK);
            let structuredARaction = result.data.map((item)=> ({
                id: item.id,
                name: item.name
            }))
            setARActionCodeList(structuredARaction || []);

        } catch (error) {
            console.error("Error while fetching ARAction code :",error)
        }
    }

    function getARClaimNotes(claimId) {
        setShowLoadingBar(true);
        let result = service.ListArClaimNotes(claimId);
        result.then((response) => {
            setNotesModalList(response.data.results);
            setShowLoadingBar(false);
        });
    }
    function postARClaimNotes(data) {
        setShowLoadingBar(true);
        let result = service.AddArClaimNotes(data);
        result.then(() => {
            notesModalReset();
            setShowNotesModal(false)
            setShowLoadingBar(false);
            showNotifyWindow('show', 'success', ADD_SUCCESS);
            props.setReloadARClaimData(true);
        }).catch(() => (
            showNotifyWindow('show', 'error', ADD_ERROR)
        ));
    }

    function UpdateArClaimNotes(notesModalVariables,data) {
        setShowLoadingBar(true);
        let result = service.UpdateArClaimNotes(notesModalVariables.id, data);
        result.then(() => {
            setShowNotesModal(false)
            setShowLoadingBar(false);
            notesModalReset();
            props.setReloadARClaimData(true)
            showNotifyWindow('show', 'success', UPDATE_SUCCESS);
        }).catch(() => (
            showNotifyWindow('show', 'error', UPDATE_ERROR)
        ));
    }

    function deleteArClaimNotes(id) {
        notesModalList.map((item) => {
            if (item.id === parseInt(id)) {
                setDeleteProviderName(item.notes);
                setDeleteProviderId(item.id);
                setShowDeleteModalWindow(true);
            }
            return null;
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        const data = service.DeleteArClaimNotes(deleteProviderId);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteProviderName("");
            setDeleteProviderId("");
            setShowDeleteModalWindow(false);
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            getARClaimNotes(props.claimPK);
        });
    }

    function createNotesData(notesModalVariables, props) {
        return {
          "notes": notesModalVariables.notes,
          "next_follow_up_date": format(new Date(notesModalVariables.next_follow_up_date), "yyyy-MM-dd"),
          "claim": props.claimPK,
          "category": 'ar_claim',
          "ar_status": notesModalVariables.ar_status_id,
          "ar_action": notesModalVariables.ar_action
        };
      }

    const onSaveArNotes = (e) => {
        e.preventDefault();
        if (!notesModalVariables.notes) return;
        if (!notesModalVariables.ar_status_id || !notesModalVariables.ar_action) {
            showNotifyWindow("show","error","Please fill the mandatory fields before saving.");
            return;
        }
        if (!notesModalVariables.next_follow_up_date ||
            notesModalVariables.next_follow_up_date == 'Invalid Date') return;

        const data = createNotesData(notesModalVariables, props);

        if (!notesModalVariables.id) {
            postARClaimNotes(data);
        } else {
            setShowLoadingBar(true);
            UpdateArClaimNotes(notesModalVariables,data);
        }
    }

    function getTabClaimData(claimId) {
        const result = service.GetOpendARClaims(claimId);
        result.then(response => {
            if (response.data) {
                if (response && response.data && response.data.active === false) {
                    setClaimActive(response.data.active);
                }
                setTabClaimData(response.data);
            }
        });
    }

    function getARClaim(claimId) {
        setShowLoadingBar(true);
        const result = service.ListARClaimData(claimId);
        result.then((response) => {
            if (response.data.data) {
                let mainData = [];
                mainData = response.data.data.map((mainDataList) => {
                    let arrLogCode = [];
                    let arrLogCodeNext = [];
                    let codeValue = '';
                    let dataObj = {};
                    let titleData = '';
                    let logCodeType = '';
                    let logTypeData = '';
                    mainDataList[11].forEach(item => {
                        if (item[11]) {
                            let index = 0;
                            item[11].forEach(item2 => {
                                index += 1;
                                let logFromArray = [];
                                let logStr = item2.splice(2, 1)[0];
                                let content = item2.splice(6, 1)[0];
                                arrLogCode = logStr.trim().split('<<');
                                logFromArray.push(arrLogCode[0]);
                                arrLogCode.forEach(logCodeItem => {
                                    if (logCodeItem.indexOf('>>') >= 0) {
                                        arrLogCodeNext = logCodeItem.trim().split('>>'); // e.g. "userby>>"  OR "practice>>." 
                                        codeValue = String(arrLogCodeNext[0]).trim();
                                        if (content[codeValue]) {
                                            dataObj = content[codeValue];
                                            titleData = (dataObj.title.trim().length) ? dataObj.title : 'unknown';

                                            logTypeData = (dataObj.type.trim().length) ? dataObj.type : '';
                                            logCodeType = codeValue + '_' + logTypeData;
                                            let idValue = '';
                                            let filterRoute = '';
                                            let textBold = '';
                                            if (logCodeRouteIds[logCodeType]) {
                                                filterRoute = logCodeRouteIds[logCodeType];
                                                idValue = (dataObj.id) ? dataObj.id : '';
                                            }
                                            else if (logCodeRoutes[logCodeType]) {
                                                filterRoute = logCodeRoutes[logCodeType];
                                                idValue = "";
                                            }
                                            else if (logCodeHighLight[logCodeType]) {
                                                filterRoute = "";
                                                idValue = "";
                                                textBold = 'text-bold';
                                            }

                                            if (filterRoute && filterRoute.length) {
                                                if (logCodeType == 'payment_payment') {
                                                    // TO OPEN THE CLAIM IN SEARCH CLAIMS PAGE, WE NEED CLAIM PKID AND CLAIMID 
                                                    idValue = content[codeValue];
                                                }
                                                logFromArray.push(<span className="logTxtColorA" onClick={e => redirectPage(e, filterRoute, idValue)} > {titleData} </span>);
                                            }
                                            else {
                                                logFromArray.push(<span className={textBold}> {titleData} </span>);
                                            }
                                            logFromArray.push(arrLogCodeNext.splice(1, arrLogCodeNext.length).join(' '));
                                        }
                                    }
                                });
                                item2.splice(2, 0, logFromArray);
                                let colorCode = '#';
                                if (item2[7] === '') {
                                    if (index % 2 == 0)
                                        colorCode += 'ffffff';
                                    else
                                        colorCode += 'eea5a5';
                                } else {
                                    colorCode += item2[7];
                                }
                                item2.splice(7, 0, colorCode);
                                item2.splice(8, 1);

                            })
                        }
                    });

                    return mainDataList

                });
                setMainDatas(mainData);
            }
            setShowLoadingBar(false);
        });
    }

    function getAssignClaimList() {
        const result = service.ListARClaimPraciceUsers(practicePK);
        result.then((response) => {
            setAssignClaimUsersList(response.data);
        })
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    
    function onRebillClick(pk, claimId) {
        let openedPKs = false;
        const result = claimService.GetSelectedTabs('claims', getStorage("practice"));
        result.then(response => {
            openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks.filter(item => parseInt(item, 10) > 0) : [];

            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_CLAIMS && !openedPKs.includes(pk)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_claim_tabs'));
            }
            else {
                let item = { pk: pk, type: 'claims', claim_id: claimId, action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);

            }
        });
    }
    function showNotesModalWindow() {
        setShowNotesModal(true);
    }
    function onHandleNotesVariable(e, name) {
        if (e && e.target) { // set notes variable
            name = e.target.name;
            let value = e.target.value;
            setNotesModalVariables({
                ...notesModalVariables, [name]: value
            });
        } else { // set next follow-up date variable
            setNotesModalVariables({
                ...notesModalVariables, [name]: e
            });
        }
    }
    function onShowActionButtons(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.toggle('show');
        dropdownMenu.classList.toggle('show');
        if (activeRowId === 0) {
            setActiveRowId(rowId);
        } else if (activeRowId === rowId) {
            setActiveRowId(0);
        } else if (this.state.activeRowId !== rowId) {
            dropdown = document.getElementById('dropdown-' + activeRowId);
            dropdownMenu = document.getElementById('dropdown-menu-' + activeRowId);
            dropdown.classList.toggle('show');
            dropdownMenu.classList.toggle('show');
            setActiveRowId(rowId);
        }
    }

    function onHideActionButtons(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.remove('show');
        dropdownMenu.classList.remove('show');
        setActiveRowId(0);
    }

    function onEditArClaimNotes(id) {
        setShowLoadingBar(true);
        let result = service.GetArClaimNotes(id);
        result.then((response) => {
            setNotesModalVariables({
                "id": response.data.id,
                "notes": response.data.notes,
                "next_follow_up_date": response.data.next_follow_up_date,
                "ar_status_id": response.data.ar_status,
                "ar_action": response.data.ar_action
            });
            setShowLoadingBar(false);
        })
    }


    function claimPostPayment() {
        history.push("/payment/post_payments", {
            'patient': tabClaimData.patient_dropdown
        });
    }

    if (redirectToClaim) {
        return <Redirect to={{ pathname: ROUTE_SEARCH_CLAIMS }} />;

    }

    if (gotoURL) {
        return <Redirect to={{ pathname: URLPath, state: { selectedID: selectedID } }} />;
    }

    function addRemoveFromSelectedTab(item) {
        const result = claimService.AddRemoveSelectedTab(item);
        result.then(() => {
            if (item && item.type == 'claims') {
                setRedirectToClaim(true);
            }
        });
    }

    function GetCPTActionLogs(pk) {
        const result = service.CPTActionLogs(pk);
        result.then((response) => {
            let subData2 = [];
            let arrLogCode = [];
            let arrLogCodeNext = [];
            let codeValue = '';
            let dataObj = {};
            let titleData = '';
            let logCodeType = '';
            let logTypeData = '';
            let bgColorCodes = {
                'red': 'bgBaseRed',
                'green': 'bgBaseGreen',
                'blue': 'bgBaseBlue',
                'white': 'bgBaseWhite',
            }
            let txtColorCodes = {
                'red': 'txtBaseRed',
                'green': 'txtBaseGreen',
                'blue': 'txtBaseBlue',
                'white': 'txtBaseWhite',
            }

            subData2 = response.data.map((subData2List, index) => {
                let logFromArray = [];
                let logStr = subData2List.splice(2, 1)[0];
                let content = subData2List.splice(6, 1)[0];
                arrLogCode = logStr.trim().split('<<');
                logFromArray.push(arrLogCode[0]);
                arrLogCode.forEach(logCodeItem => {
                    if (logCodeItem.indexOf('>>') >= 0) {
                        arrLogCodeNext = logCodeItem.trim().split('>>'); // e.g. "userby>>"  OR "practice>>." 
                        codeValue = String(arrLogCodeNext[0]).trim();
                        if (content[codeValue]) {
                            dataObj = content[codeValue];
                            titleData = (dataObj.title.trim().length) ? dataObj.title : 'unknown';

                            logTypeData = (dataObj.type.trim().length) ? dataObj.type : '';
                            logCodeType = codeValue + '_' + logTypeData;
                            let idValue = '';
                            let filterRoute = '';
                            let textBold = '';
                            if (logCodeRouteIds[logCodeType]) {
                                filterRoute = logCodeRouteIds[logCodeType];
                                idValue = (dataObj.id) ? dataObj.id : '';
                            }
                            else if (logCodeRoutes[logCodeType]) {
                                filterRoute = logCodeRoutes[logCodeType];
                                idValue = "";
                            }
                            else if (logCodeHighLight[logCodeType]) {
                                filterRoute = "";
                                idValue = "";
                                textBold = 'text-bold';
                            }

                            if (filterRoute && filterRoute.length) {
                                if (logCodeType == 'payment_payment') {
                                    // TO OPEN THE CLAIM IN SEARCH CLAIMS PAGE, WE NEED CLAIM PKID AND CLAIMID 
                                    idValue = content[codeValue];
                                }
                                logFromArray.push(<span className="logA" onClick={e => redirectPage(e, filterRoute, idValue)} > {titleData} </span>);
                            }
                            else {
                                logFromArray.push(<span className={textBold}> {titleData} </span>);
                            }
                            logFromArray.push(arrLogCodeNext.splice(1, arrLogCodeNext.length).join(' '));
                        }
                    }
                });
                subData2List.splice(2, 0, logFromArray);
                subData2List.splice(7, 0, false);
                let colorCode = '';
                if (bgColorCodes[subData2List[8]]) {
                    colorCode += bgColorCodes[subData2List[8]];
                } else {
                    if (index % 2 == 0) {
                        colorCode += 'bgRed';
                    } else {
                        colorCode += 'bgWhite';
                    }
                }
                if (txtColorCodes[subData2List[9]]) {
                    colorCode += ' ' + txtColorCodes[subData2List[9]];
                } else {
                    if (index % 2 == 0) {
                        colorCode += ' ' + 'bgRed';
                    } else {
                        colorCode += ' ' + 'bgWhite';
                    }
                }
                subData2List.splice(8, 0, colorCode);
                subData2List.splice(9, 1);
                return subData2List
            });
            setSubDatas2(subData2);

        });

    }

    function redirectPage(e, routeValue, idValue) {
        if (routeValue) {
            setURLPath(routeValue);
            setSelectedID(idValue);
            setGotoURL(true);
        }
    }
    function assign_A_user(data) {
        setShowLoadingBar(true);
        const result = service.AssignUserAgainstPractice(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 201 || response.status === 200) {
                if (response.status === 201)
                    showNotifyWindow('show', 'success', ADD_SUCCESS);
                else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                props.getMyArTableData();
                resetForm();
                setShowAssignModal(false);
                props.onSearchARClaims()
            }
            else {
                showNotifyWindow('show', 'error', ADD_ERROR);
            }
        })
    }

    const InactiveClaim = async () => {
        setShowLoadingBar(true);
        let result = service.ClaimInactive(props.claimPK, { 'active': false });
        result.then((response) => {
            if (response.status === 200)
                showNotifyWindow('show', 'success', DELETE_SUCCESS);
        })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setShowLoadingBar(false);
            })
    }
    const UnAssignClaim = async () => {
        setShowLoadingBar(true);
        let result = service.UnAssignUserAgainstPractice(props.claimPK);
        result.then((response) => {
            if (response.status === 204) {
                getAssignClaimList();
                props.getMyArTableData();
                props.onSearchARClaims()
                showNotifyWindow('show', 'success', "UnAssigned user");
            }
        })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setShowLoadingBar(false);
            })
    }

    const onHandleSelectChange = (e) => {
        let {value, name} = e.target;

        if (name == "ar_status_id")
        setNotesModalVariables({
            ...notesModalVariables,
            "ar_status_id" : value
        })

         if (name == "ar_action")
            setNotesModalVariables({
                ...notesModalVariables,
                "ar_action" : value
            })
    }


    const getNotes = (notes) => {
        return <p
            style={{
                flexWrap: 'wrap',
                whiteSpace: 'initial',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word'
            }}>{notes}</p>
    }

    

    function arClaimNotesTable () {
       return <table className='table table-margin-bottom' id='overflow_auto' >
        <thead>
            <tr>
                <th>{i18n.t('arClaims.notesModal.notes')}</th>
                <th>{i18n.t('arClaims.notesModal.arStatus')}</th>
                <th>{i18n.t('arClaims.notesModal.arAction')}</th>
                <th>{i18n.t('arClaims.notesModal.next_follow_up_date')}</th>
                <th>{i18n.t('arClaims.notesModal.last_follow_up_date')}</th>
                <th>{i18n.t('arClaims.notesModal.action')}</th>
            </tr>
        </thead>
        <tbody>
            {
                notesModalList && notesModalList.map((item) => {
                    return (
                        <tr key={item.id}>
                            <td style={{maxWidth:'250px'}}>{getNotes(item.notes)}</td>
                            <td style={{maxWidth:'250px'}}>{getNotes(item.ar_status_name)}</td>
                            <td style={{maxWidth:'250px'}}>{getNotes(item.ar_action_name)}</td>
                            <td  align='center'><Moment format='MM/DD/YYYY'>{item.next_follow_up_date}</Moment></td>
                            <td  align='center'><Moment format='MM/DD/YYYY'>{item.modified_on}</Moment></td>
                            <td  align='center'>
                                <div className="dropdown" id={'dropdown-' + item.id}>
                                    <button id={'dropdownMenuButton-' + item.id} className="dropdown-toggle" type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        onClick={(e) => onShowActionButtons(e, item.id)}
                                        onBlur={(e) => onHideActionButtons(e, item.id)}
                                    >
                                        <img id="test" src={actionImg} alt="Action" />
                                    </button>
                                    <div className="dropdown-menu" id={'dropdown-menu-' + item.id}>
                                        <Link className="dropdown-item edit" to="#"
                                            onMouseDown={() => onEditArClaimNotes(item.id)}
                                        >{i18n.t('commons.edit')}</Link>
                                        <Link className="dropdown-item edit" to="#"
                                            onMouseDown={() => deleteArClaimNotes(item.id)}
                                        >{i18n.t('commons.delete')}</Link>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>
    </table>
    }

    function arClaimAssignTable() {
    return <table className="table" id="hover">
        <thead>
            <tr>
                <th>{i18n.t("arClaims.assignClaimTab.slNum")}</th>
                <th>{i18n.t("arClaims.assignClaimTab.name")}</th>
            </tr>
        </thead>
        <tbody>
            {!assignClaimUsersList && assignClaimUsersList.length === 0 &&
                <tr>
                    <td align="center" colSpan='2'>
                        {noRecordsFound}
                    </td>
                </tr>
            }
            {assignClaimUsersList.length > 0 && assignClaimUsersList.map((item, index) => {
                let selected = false;
                if (props.claimData && props.claimData.assigned_to_id
                    && item.id.toString() === props.claimData.assigned_to_id.toString()) {
                    selected = true;
                }
                return (
                    <tr className={selected ? 'tr-selected' : ''} key={index} onClick={() => assign_A_user({ "claim": [props.claimPK], "user": item.id })}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                    </tr>
                )
            })
            }
        </tbody>
    </table>
    }

    return (
        <>
            <div>
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="">
                    {claimActive && <>
                        <div className='row ml-2' style={{ display: 'flex' }}>
                            {
                                checkPermission(permission_key_values_claim.claims_ar_claims_modify) &&
                                <div style={{ height: '60px' }}>
                                        <IconButtons icon="note" ToolTip={i18n.t('arClaims.notes')} onClick={showNotesModalWindow} />
                                    </div>
                            }
                            {
                                checkPermission(permission_key_values_claim.claims_search_claims_view, permission_key_values_claim.claims_ar_claims_modify) &&
                                <div style={{ height: '60px' }}>
                                        <IconButtons icon="bill" ToolTip={i18n.t('arClaims.reBill')} onClick={() => onRebillClick(props.claimPK, props.claimData.custom_claim_id)} />
                                    </div>
                            }
                            {
                                checkPermission(permission_key_values_payments.post_payments, permission_key_values_claim.claims_ar_claims_modify) &&
                                <div style={{ height: '60px' }}>
                                        <IconButtons icon="plus" onClick={() => assignClaim()} ToolTip={i18n.t('arClaims.assignClaim')} />
                                    </div>
                            }
                            {checkPermission(permission_key_values_claim.claims_ar_claims_modify) &&
                                <div style={{ height: '60px' }}>
                                    <IconButtons icon="person" onClick={() => claimPostPayment()} ToolTip={i18n.t('arClaims.postPayment')} />
                                </div>
                            }
                            {checkPermission(permission_key_values_claim.claims_ar_claims_modify) &&
                                <div style={{ display: 'flex !important', flexDirection: 'column !important' }}>
                                    <div style={{ height: '60px' }}>
                                        <IconButtons icon="three-dot" onClick={handleClick} ToolTip={"Assign or Unassign"} />
                                    </div>
                                    <Menu
                                        id="assign-unassign-dropdown"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'assign-unassign-dropdown',
                                        }}
                                    >
                                        <MenuItem sx={{ display: 'block !important', padding: '5px !important' }} key={1} onClick={() => { InactiveClaim(); handleClose(); }}>{i18n.t("arClaims.delete")}</MenuItem>
                                        <MenuItem sx={{ display: 'block !important', padding: '5px !important' }} key={2} onClick={() => { UnAssignClaim(); handleClose(); }}>UnAssign</MenuItem>
                                    </Menu>
                                </div>
                            }



                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column',alignItems:'flex-end' }}>
                                {tabClaimData.responsible?.name &&
                                    <div className='right-cell-item'>
                                        
                                        <span className='left-item'>Insurance:</span>
                                        <span className='right-item'>
                                         {tabClaimData?.responsible?.name?.length > 45
                                           ? `${tabClaimData?.responsible?.name?.slice(0, 45)}...` 
                                           : tabClaimData?.responsible?.name
                                        }
                                        </span>
                                    </div>
                                }
                                {tabClaimData.responsible?.payer_id &&
                                    <div className='right-cell-item'>
                                        <span className='left-item'>Payer Id:</span>
                                        <span className='right-item'>{tabClaimData.responsible.payer_id}</span>
                                    </div>
                                }
                                {tabClaimData?.payer_types?.type &&
                                    <div className='right-cell-item'>
                                        <span className='left-item'>Payer Type:</span>
                                        <span className='right-item'>{tabClaimData.payer_types?.type?.charAt(0).toUpperCase() + tabClaimData.payer_types?.type?.slice(1)}</span>
                                    </div>
                                }
                                {tabClaimData?.dob &&
                                    <div className='right-cell-item'>
                                        <span className='left-item'>Patient DOB:</span>
                                        <span className='right-item'>{CommonDateFormat(tabClaimData.dob)}</span>
                                    </div>
                                }
                                {tabClaimData?.responsible?.policy_id &&
                                    <div className='right-cell-item'>
                                        <span className='left-item'>Policy Id:</span>
                                        <span className='right-item'>{tabClaimData?.responsible?.policy_id}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </>}
                    <div className="table">
                        <div className='tableRow' style={{ overflowX: 'auto', width: "100%"}}>
                            <TableComponent mainHeadings={mainHeadings} mainDatas={mainDatas} setMainDatas={setMainDatas} subHedaings1={subHedaings1} subDatas1={subDatas1} setSubDatas1={setSubDatas1} subHedaings2={subHedaings2} subDatas2={subDatas2}
                                getSubData2={GetCPTActionLogs} keepExpandedState={false} />
                        </div>
                    </div>
                    <div>
                        <CustomizedDialogs header={i18n.t('arClaims.notesModal.notes')} showModal={showNotesModal} submit={false} setShowModalWindow={setShowNotesModal} resetForm={notesModalReset}>
                            <div className='col-4 pl-0 '>
                                <CalendarInput required={true} name='next_follow_up_date' minDate={new Date()} selected={notesModalVariables.next_follow_up_date} onValueChange={(e) => onHandleNotesVariable(e, 'next_follow_up_date')} label={i18n.t('arClaims.notesModal.next_follow_up_date')} />
                            </div>
                            <div className='pb-4'>
                                <TextAreaInput required={true} type={'text'} name={'notes'} value={notesModalVariables.notes} onValueChange={onHandleNotesVariable} label={i18n.t('arClaims.notesModal.notes')} />
                            </div>

                            <div className='row'>
                                <div className='col-4'>
                                    <SelectInput data={ARStatusCodeList} name="ar_status_id" required={true} value={notesModalVariables.ar_status_id} onValueChange={(e)=>onHandleSelectChange(e)} label={i18n.t('arClaims.notesModal.arStatus')} />
                                </div>
                                <div className='col-4'>
                                    <SelectInput data={ARActionCodeList} name="ar_action" required={true} value={notesModalVariables.ar_action} onValueChange={(e)=>onHandleSelectChange(e)} label={i18n.t('arClaims.notesModal.arAction')} />
                                </div>
                            </div>

                            <div className='justify-right pb-4'>
                                <button type='button' className='btn btn-primary-blue' onClick={onSaveArNotes}>{i18n.t('commons.save')}</button>
                            </div>
                            <div className="modal-table-container">
                               {arClaimNotesTable()}
                            </div>

                        </CustomizedDialogs>
                    </div>
                    <div className="">
                        <CustomizedDialogs type="save" header={header} showModal={showAssignModal} setShowModalWindow={setShowAssignModal} resetForm={resetForm} >
                           {arClaimAssignTable()}
                        </CustomizedDialogs>
                    </div>
                </div>
            </div>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={notesModalReset} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("patientAddNote.deleteConfirmation") + " '" + deleteProviderName + "'?"}
            </CustomizedSmallDialogs>
        </>
    )
}

export default ARClaimTab;