import React, { useContext, useEffect, useState } from "react";
import Notify from "../../commons/notify";
import BackArrowWithLabel from "../../commons/Back";
import { getStorage } from "../../../utilities/browserStorage";
import TextInput from '../../commons/input/input';
import i18n from "../../../utilities/i18n";
import CommonButton from "../../commons/Buttons";
import { ARactionCodeTableObject } from "./ARactionCodeTable";
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX, ROUTE_AR_ACTION_CODE, ROUTE_PRACTICE_DICTIONARIES, ROUTE_SUPER_DICTIONARIES } from "../../../utilities/staticConfigs";
import { checkPermission, commonTableBody } from "../../../utilities/commonUtilities";
import Table from "../../commons/Table/Table";
import Pagination from "../../commons/pagination";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import apiService from './service';
import { Form } from "react-bootstrap";
import LoadingContext from "../../../container/loadingContext";
import { useHistory } from "react-router-dom";
import { ADD_ERROR, ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from "../../../utilities/labelConfigs";
import { customer_admin_privilege, permission_key_values_practice_dictionaries, super_admin_permission_key_value, super_admin_privileges } from "../../../utilities/permissions";
const ARactionCodeList = () => {
    const practicePK = getStorage("practice");
    const isAdminModule = getStorage('isAdminModule');
    const setShowLoadingBar = useContext(LoadingContext);
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [searchValue, setSearchValue] = useState('');
    const history = useHistory();
    const [ARCodeList, setARCodeList] = useState([]);
    const [defaultValue, setDefaultValue] = useState(false);
    const [permission, setPermission] = useState('');
    
    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    //modal state
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [deleteARCode, setDeleteARCode] = useState();
    const [editForm, setEditForm] = useState(false);
    const [arcode, setARCodeName] = useState();
    const [form_error, setFormError] = useState({ 'arcode': '' });
    const [deleteARcodeId, setDeleteARcodeId] = useState("");
    const [editARcodeId, setEditARcodeId] = useState("");
    const [header, setHeader] = useState('');
    const [notDelete, setNotDelete] = useState(false);

    /**
     * Notification function to show notification in error and success case
     * @param {*} action 
     * @param {*} type 
     * @param {*} desc 
     * @param {*} age 
     */
    const showNotifyWindow = (action, type, desc, age = 3000) => {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    useEffect(() => {
        if (history.location?.pathname == ROUTE_AR_ACTION_CODE) {
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege, customer_admin_privilege, super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                super_admin_privileges.super_admin_full_privilege
            ))
        }
        getARActionCodeList(DEFAULT_PAGING_SIZE, activePage);
    }, [])

    /**
     * The function will call ar action code list api and get ar action code
     * @param {*} pageSize 
     * @param {*} page 
     * @param {*} searchString 
     */
    const getARActionCodeList = async (pageSize, page, searchString) => {
        setShowLoadingBar(true);
        const result = await apiService.GetListARActionCode(pageSize, page, isAdminModule,
            isAdminModule === "true" ? "" : practicePK,
            searchString);
        if (result.status == 200 && result.data.results) {
            setShowLoadingBar(false);
            if (result.data.results !== undefined) {
                setTotalPage(
                    Math.ceil(result.data.count / result.data.page_size)
                );
            }

            result.data.results = result.data.results?.map(item => {
                setNotDelete(item.not_delete);                
                if (item.not_delete === true) {
                    item.isDefault = "Yes";
                } else {
                    item.isDefault = "No";
                }
                return item;
            });

            const rowArray = commonTableBody(
                result.data.results,
                ARactionCodeTableObject.tableBodyData[0]
            );
            ARactionCodeTableObject.tableBodyData = rowArray;
            setARCodeList(result.data.results);
        } else {
            setShowLoadingBar(false);
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        }
    }

    const backToDictionaryPage = () => {
        if (isAdminModule === "true") {
            history.push(ROUTE_SUPER_DICTIONARIES);
        } else {
            history.push(ROUTE_PRACTICE_DICTIONARIES);
        }
    }

    /**
     * The onHandleChange function is used to store input value to the state
     * @param {*} e 
     * @returns 
     */

    const onHandleChange = (e) => {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (name == "searchValue") {
            setSearchValue(trimmedValue)
            return;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setARCodeName(trimmedValue);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchValue) {
            onSearch(e, searchValue);
        }
    }

    /**
     * after type search value and click seaarch button this function will evoke and give search result
     * @param {*} e 
     * @param {*} searchString 
     */
    const onSearch = (e, searchString) => {
        e.preventDefault();
        setActivePage(1);
        setStartIndex(0);
        getARActionCodeList(DEFAULT_PAGING_SIZE, 1, searchString);
    }

    const onPagePrevious = () => {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }

        getARActionCodeList(DEFAULT_PAGING_SIZE, previousPage, searchValue);
    }

    const onPageUp = (e) => {
        let page = Number(e.target.id)
        setActivePage(page);
        getARActionCodeList(DEFAULT_PAGING_SIZE, page, searchValue);
    }

    const onPageNext = () => {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getARActionCodeList(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }

    /**
     * if user type and click close on the textinput this function will trigger
     * And call all ar action codes
     */
    const onClose = () => {
        setSearchValue("");
        setActivePage(1);
        setStartIndex(0);
        getARActionCodeList(DEFAULT_PAGING_SIZE, activePage);
    }

    const addNew = () => {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setHeader(i18n.t("dictionariesPages.arActionCode.addHeader"));
        setShowModalWindow(true);
    }

    /**
     * When we click edit or delete button the below function is evoked
     * the name parameter contains action eg @delete or @edit
     * @param {*} id 
     * @param {*} name 
     */
    const dropDownFunction = (id, name) => {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name.toLowerCase() == 'edit') {
            if (notDelete) {
                showNotifyWindow('show', 'error', "Cannot edit default value.");
                return;
            }
            onEditARCode(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteARCode(Number(id));
        }
    }

    /**
     * It will call the api to get the perticular ar code details using the @arcode_id
     * If the response fetched it will pop up edit modal window
     * @param {*} arcode_id 
     */
    const onEditARCode = async (arcode_id) => {
        try {
            setShowLoadingBar(true);
            const response = await apiService.GetARActionCode(arcode_id, isAdminModule);
            if (response?.data) {
                setARCodeName(response.data?.name)
                setEditARcodeId(arcode_id);
            }
            setEditForm(true)
            setHeader(i18n.t("dictionariesPages.arActionCode.editHeader"));
            setShowModalWindow(true);
            setShowLoadingBar(false);
            setDefaultValue(response.data?.not_delete);
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        } finally {
            setShowLoadingBar(false);
        }
    }

    /**
     * This will store delete arcode name and id
     * also it will make delete modal window state to true
     * @param {*} arcode_id 
     */
    const onDeleteARCode = (arcode_id) => {
        ARCodeList.map((item) => {
            if (item.id == parseInt(arcode_id)) {
                setDeleteARCode(item.name);
                setDeleteARcodeId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    /**
     * function to delete already added ar code
     */
    const onDeleteAction = () => {
        setShowLoadingBar(true);
        let data = apiService.DeleteARActionCode(
            deleteARcodeId,
            isAdminModule
        );
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteARCode("");
            setDeleteARcodeId("");
            setShowDeleteModalWindow(false);
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            getARActionCodeList(DEFAULT_PAGING_SIZE, activePage, searchValue);
        }).catch(() => {
            setShowLoadingBar(false);
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        });
    }

    /**
     * when clicking reset form it will reset all form datas and field errors
     */
    const resetForm = () => {
        setARCodeName('');
        setEditForm(false);
        setDefaultValue(false);
        setEditARcodeId("");
        setFormError({
            ...form_error,
            ['arcode']: ''
        });

    }

    /**
     * function to hide the modal window
     */
    const onHide = () => {
        setShowModalWindow(false);
        setEditForm(false);
        setDefaultValue(false);
        setEditARcodeId("");
        setARCodeName("");
    }

    /**
     * Here this function will close the delete modal window and all state related to delete functionality
     */
    function onDeleteHide() {
        setDeleteARCode('');
        setDeleteARcodeId('');
        setShowDeleteModalWindow(false);
    }


    /**
     * This function will save new ar code 
     * @param {*} e 
     */
    const onSaveFormData = async (e) => {
        e.preventDefault();
        if (!arcode || typeof arcode !== "string" || !arcode.trim()) {
            setFormError((prevState) => ({
                ...prevState,
                ["arcode"]: "error",
            }));
            return;
        }

        setShowLoadingBar(true);
        const data =
            isAdminModule === "true"
                ? { name: arcode }
                : { practice: practicePK, name: arcode };
        const result = editForm
            ? apiService.UpdateARActionCode(editARcodeId, data, isAdminModule)
            : apiService.AddARActionCode(data, isAdminModule);
        result
            .then((response) => {
                setShowLoadingBar(false);

                if (!response || !response.data || typeof response.data !== "object") {
                    throw new Error("Invalid response format");
                }
                if (response.data?.message === "name_unique") {
                    showNotifyWindow("show", "error", i18n.t("dictionariesPages.arActionCode.ARActionAlreadyExist"));
                } else if (response.status === 200 || response.status === 201) {
                    showNotifyWindow("show", "success", editForm ? UPDATE_SUCCESS : ADD_SUCCESS);
                    getARActionCodeList(DEFAULT_PAGING_SIZE, activePage, searchValue);
                } else if (
                    response.status === 400 &&
                    response.data.record_already_exists !== undefined
                ) {
                    showNotifyWindow(
                        "show",
                        "error",
                        i18n.t("errorMessages.record_exists")
                    );
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
                resetForm();
                setShowModalWindow(false);
            })
            .catch(() => {
                setShowLoadingBar(false);
                showNotifyWindow("show", "error", ADD_ERROR);
            });
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                {isAdminModule === "true" ? (
                    <div className="box box-content-white mb-3">
                        <div className="row mr-0 mb-2">
                            <div className="col pl-4 mb-1">
                                <div className={"dataTables_filter"}>
                                    <div
                                        className="link dictionaries-back"
                                        onClick={backToDictionaryPage}>
                                        <BackArrowWithLabel
                                            label={i18n.t(
                                                "dictionariesPages.gotoDictionaries"
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-content">
                            <div className="common-search-wrapper-style">
                                <div className="input-content-box">
                                    <div className="form-group padding-top15 relative">
                                        <TextInput
                                            type="text"
                                            id="searchValue"
                                            name="searchValue"
                                            value={searchValue}
                                            onValueChange={(e) => onHandleChange(e)}
                                            label={i18n.t(
                                                "dictionariesPages.arActionCode.arActionCode"
                                            )}
                                            onKeyDown={(e) => handleKeyDown(e)}
                                            labelClassName={"margin-bottom0"}
                                            clearButton={
                                                typeof searchValue ==
                                                "string" &&
                                                searchValue.length !== 0
                                            }
                                            clearInput={(e) => onClose(e)}
                                        />
                                    </div>
                                </div>
                                <div className="input-content-box padding-top15">
                                    <div className="margin-top18">
                                        <CommonButton
                                            variant="contained"
                                            label="Search"
                                            onClick={(e) =>
                                                onSearch(e, searchValue)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="box box-content-white">
                        <div className="box-head pl-0 pr-0">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back pl-0"
                                    onClick={(e) => backToDictionaryPage(e)}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={(e) => addNew(e)}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={ARactionCodeTableObject}
                                dropDownFunction={(id, name) => dropDownFunction(id, name)}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={(e) => onPagePrevious(e)}
                                onPageUp={(e) => onPageUp(e)}
                                onPageNext={(e) => onPageNext(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={() => resetForm()}
                onHide={() => onHide()}>
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="arcode"
                                        name="arcode"
                                        disabled={defaultValue}
                                        label={i18n.t(
                                            "dictionariesPages.arActionCode.arActionCode"
                                        )}
                                        onValueChange={(e) => onHandleChange(e)}
                                        defaultClassName={
                                            form_error.arcode
                                                ? "input-error"
                                                : ""
                                        }
                                        value={arcode}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={() => onDeleteAction()}
                resetForm={() => resetForm()}
                onHide={() => onDeleteHide()}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.arActionCode.deletConfirmation") +
                    "'" +
                    deleteARCode +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    )

}

export default ARactionCodeList;