// React and related libraries
import React, { useEffect, useState, useContext } from 'react'
import { Link, Redirect } from "react-router-dom";
import { Form } from "react-bootstrap";

// Third-party libraries
import format from 'date-fns/format';
import { set, get, clear } from 'idb-keyval';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Local utilities and constants
import i18n from "../../../utilities/i18n";
import { getStorage } from '../../../utilities/browserStorage';
import { commonTableBody, checkPermission } from '../../../utilities/commonUtilities';
import { ERA_PAYMENT_MODE, SHOW_TYPE, SELECT_OPTION_ALL, RESPONSIBILITY_TYPES } from '../../../utilities/dictionaryConstants';
import { DEFAULT_PAGING_SIZE, DEFAULT_DATE_FORMAT, MAX_OPEN_TABS_PATIENTS, ROUTE_PATIENTS_LIST, PAGING_END_INDEX } from '../../../utilities/staticConfigs';
import { permission_key_values_payments } from '../../../utilities/permissions';

// Local services
import service from '../service'
import serviceClaim from '../../ClaimsModule/SearchClaims/service';

// Local components
import { ERAPostingTableDataSearch } from '../ERAPostingTable';
import Notify from '../../commons/notify';
import TextInput from "../../commons/input/input";
import SelectInput from '../../commons/input/SelectInput';
import CalendarInput from "../../commons/input/CalendarInput";
import AsyncTypeInput from '../../commons/input/AsyncTypeHead/AsyncTypeInput'
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';
import CurrencyInputs from '../../commons/input/CurrencyInput';
import ERASearchTable from './ERASearchTable';
import ERAApply from './ERAApply';
import CommonButton from '../../commons/Buttons';

// Contexts
import LoadingContext from "../../../container/loadingContext";

// Styles
import '../style.css'
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

export const ERAPosting = () => {
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const [header, setHeader] = useState("");
    const [searchKey, setSearchKey] = useState(false);
    const [reviewKey, setReviewKey] = useState(false);
    const [eraPayerList, setEraPayerList] = useState([]);

    const [eraVariables, setEraVariables] = useState({
        "payer": "", "payment_date_from": "", "payment_date_to": "",
        "era_uploaded_date_from": "", "era_uploaded_date_to": "",
        "payment_reference": "", "payment_amount": "", "show_type": "", "payment_mode": ""
    });
    const [searchPayer, setSearchPayer] = useState([]);

    // ERA list data
    const [clearFlag, setClearFlag] = useState(0)
    const [eraList, setEraList] = useState([]);
    const [totalCount, setTotalCount] = useState("");
    const [selectedEraList, setSelectedEraList] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPaymentPK, setSelectedPaymentPK] = useState('');
    const [selectedClaimID, setSelectedClaimID] = useState('');
    const [reviewData, setReviewData] = useState([]);
    const [procedureData, setProcedureData] = useState([]);
    const [adjustment_1_codes_list, setAdjustment_1_codes_list] = useState([]);
    const [adjustment_2_codes_list, setAdjustment_2_codes_list] = useState([]);
    const [editedProcedureData, setEditedProcedureData] = useState({});
    const [eraOriginalData, setEraOriginalData] = useState();
    const [isReviewAll, setIsReviewAll] = useState(false);
    const [isNewClaim, setIsNewClaim] = useState(false);
    const [pageLimit, setPageLimit] = useState(DEFAULT_PAGING_SIZE);

    // const [paymentPK, setPaymentPK] = useState('');
    // const [saveProcedures, setSaveProcedures] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const [eraAppliedStatus, setEraAppliedStatus] = useState(null);
    const [isAllProcedureApplied, setIsAllProcedureApplied] = useState(null);
    const [eraListFlag, setEraListFlag] = useState(false);
    const [paymentAdjustmentType, setPaymentAdjustmentType] = useState([]);
    const [multiPayAdjType, setMultiPayAdjType] = useState([]);
    const [paymentAdjustmentAmount, setPaymentAdjustmentAmount] = useState(0.00);
    const [payAdjTypeArr, setPayAdjTypeArr] = useState([])
    // edit era details
    const [showEditERAModal, setShowEditERAModal] = useState(false);
    const [editPayerName, setEditPayerName] = useState("");
    const [editEFT, setEditEFT] = useState("");
    const [editCheckAmount, setEditCheckAmount] = useState("");
    const [editCheckDate, setEditCheckDate] = useState("");
    const [editEraVariables, setEditEraVariables] = useState({
        "payer_name": "", "eft": "", "check_date": "",
        "check_amount": "",
    });
    const [redirectToPatient, setRedirectToPatient] = useState(false);

    // Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [insurancePaymentVariables, setInsurancePaymentVariables] = useState({
        "era_payer": "", "era_payer_name": "", "payment_date": "", "paymentDate": "", "reference_check_number": "", "amount": "",
        "payment_mode": "", "card_type": null, "level_adjustments": 0, "adjustment_type": [], "note": ""
    });
    const [manuallyAddedClaimIDs, setManuallyAddedClaimIDs] = useState([]);
    const [claimReviewedList, setClaimReviewedList] = useState([]);
    const [responsibilityTypes, setResponsibilityTypes] = useState([]);
    const [responsibilityList, setResponsibilityList] = useState([]);
    const [lastOpenedEraRowItem, setLastOpenedEraRowItem] = useState('');
    const [lastOpenedClaimRowItem, setLastOpenedClaimRowItem] = useState('');
    const [originalResponsibility, setOriginalResponsibility] = useState([]);
    const [claimStatusList, setClaimStatusList] = useState([]);
    const [claimSubStatusList, setClaimSubStatusList] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    // eslint-disable-next-line no-undef
    var fileDownload = require('js-file-download');


    /*** PAGINATION FUNCTIONALITY STARTS HERE */
    /**************************************** */
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        const previousPage = startIndex - PAGING_END_INDEX + 1;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }

        searchERA(previousPage,"onPagePrevious");
    }

    function onPageNext() {
        const nextPage = startIndex + PAGING_END_INDEX + 1;
        const canIncrementIndexes = endIndex === totalPage || totalPage <= PAGING_END_INDEX;

        setActivePage(nextPage);
        setStartIndex(canIncrementIndexes ? startIndex : startIndex + PAGING_END_INDEX);
        setEndIndex(canIncrementIndexes ? startIndex : endIndex + PAGING_END_INDEX);

        searchERA(nextPage,"onPageNext")
    }

    function onPageUp(e) {
        const page = Number(e.target.id);
        setActivePage(page);

        searchERA(page,"onPageUp");
    }
    /*** PAGINATION FUNCTIONALITY ENDS HERE */
    /**************************************** */

    /**
     * calculating the start and end item to show in the bottom (PAGE LIMIT)
     */
    const startItem = (activePage - 1) * pageLimit + 1;
    const endItem = Math.min(activePage * pageLimit, totalCount);

    useEffect(() => {
        getAdjustmentTypes()
        if (!claimStatusList.length) getClaimStatusList(0);
        if (!claimSubStatusList.length) getClaimSubStatusData(0);
    }, [])

    const getClaimStatusList = async (pageSize) => {
        try {
            let pageNum = 0;
            const response = await service.ListClaimStatus(pageSize, pageNum, practicePK, null);
            if (response && response?.data) {
                setClaimStatusList(response.data);
            }
        }
        catch (error) {
            console.error("Error while fetching claim status list:", error)
        }
    }


    const getClaimSubStatusData = async (pageSize) => {
        try {
            let page = 0;
            const response = await service.ListClaimStatusSubStatus(pageSize, page, practicePK);
            if (response && response?.data) {
                setClaimSubStatusList(response.data);
            }
        }
        catch (error) {
            console.error("Error while fetching claim sub-status list:", error);
        }
    }


    function showSearch() {
        setSearchKey(false);
        setReviewKey(false);
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onHandleChangeEraVariables(e, names) {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.value;
            if (name && name === "payment_amount") {
                value = String(e.target.value).trim().replace(/[^0-9.]/g, '');
            }
            setEraVariables({
                ...eraVariables, [name]: value
            });
        } else if (e && e.length > 0 && names) {
            let name = names;
            let value = e[0].id;
            setEraVariables({
                ...eraVariables, [name]: value
            });
            if (name === 'payer' && e.length > 0) {
                setSearchPayer(e);
            }
        } else if (e.length === 0 && names == 'payer') {  // To clear the current selection from Search ERA AsyncTypeInput
            setSearchPayer([]);
            setEraVariables({ ...eraVariables, payer: "" })
        }

    }

    function onHandleChangeEraDate(value, name) {
        setEraVariables({
            ...eraVariables, [name]: value
        });
    }

    function resetERASearch() {
        setEraVariables({
            "payer": "", "payment_date_from": "", "payment_date_to": "",
            "era_uploaded_date_from": "", "era_uploaded_date_to": "",
            "payment_reference": "", "payment_amount": "", "show_type": "", "payment_mode": ""
        });
        setSearchPayer([])
        setClearFlag(clearFlag + 1)
        setActivePage(1);
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
    }

    /**
     * On ERA Search Component search btn & Pagination Click
     * @param {*} page // Page to be called
     * @param {*} calledFrom  // Pagination or Search Button click
     */
    function searchERA(page, calledFrom) {
        if (!validateDates()) {
            return;
        }

        setShowLoadingBar(true);

        const query = generateQueryString(page,calledFrom);
        const result = service.GetEraListData(practicePK, query)
        result.then((response) => handleResponse(response, page, calledFrom));
    }

    //Mark an era as applied or unapplied by selecting the corresponding checkbox in the era table.
    function onChecked(e, id) {
        let selected = e.target.checked;

        ERAPostingTableDataSearch.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[index].value = selected;
            }
        });

        let temp = selectedEraList;
        if (selectedEraList.indexOf(id) >= 0) {
            temp.splice(selectedEraList.indexOf(id), 1);
        } else {
            temp.push(id)
        }
        setIsChecked(!isChecked);
        setSelectedEraList(temp);
        if (temp.length > 0 && eraList.length === temp.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }

    function validateDates() {
        if (eraVariables.payment_date_to && eraVariables.payment_date_to < eraVariables.payment_date_from) {
            showNotifyWindow('show', 'error', i18n.t("commons.dateOrder"))
            return false;
        } else if (eraVariables.era_uploaded_date_to && eraVariables.era_uploaded_date_to < eraVariables.era_uploaded_date_from) {
            showNotifyWindow('show', 'error', i18n.t("commons.dateOrder"))
            return false;
        }
        return true;
    }

    function handleResponse(response, page, calledFrom) {
        setSelectedEraList([])
        setSelectAll(false)
        setShowLoadingBar(false);
        if (response?.data?.results?.length) {
            setTotalPage(Math.ceil(response.data.count / response.data.page_size))
        } else {
            setTotalPage(1);
        }
        if (calledFrom === 'search') {
            setActivePage(page);
            setStartIndex(0);
            setEndIndex(PAGING_END_INDEX);
        }
        const rowArray = commonTableBody(response?.data?.results, ERAPostingTableDataSearch?.tableBodyData[0]);
        ERAPostingTableDataSearch.tableBodyData = rowArray;
        setEraList(response?.data?.results || []);
        setTotalCount(response.data?.count);
        setEraListFlag(!eraListFlag)
        setSearchKey(true);
    }

    function generateQueryString(page,calledFrom) {
        let pageSize;
        if (calledFrom === "search") {
            pageSize =  DEFAULT_PAGING_SIZE
        } else if (calledFrom === "PageLimit"){
            pageSize =  page;
            page = 1
        } else if (calledFrom === "onPageUp" || calledFrom === "onPageNext" || calledFrom === "onPagePrevious"){
            pageSize =  pageLimit
        } 
        let query = `?page_size=${pageSize}&page=${page ?? activePage}&practice_pk=${getStorage('practice')}`;
        const eraVariableKeys = [
            'payer',
            'payment_date_from',
            'payment_date_to',
            'era_uploaded_date_from',
            'era_uploaded_date_to',
            'payment_reference',
            'payment_amount',
            'show_type',
            'payment_mode'
        ];

        for (const key of eraVariableKeys) {
            if (eraVariables[key]) {
                let value = eraVariables[key];
                if (key.includes('date')) {
                    value = format(value, 'yyyy-MM-dd');
                }
                query += `&${key}=${value}`;
            }
        }
        return query;
    }
    /***********************************/

    const handlePageSizeChange = (newPageSize) => {
        setPageLimit(Number(newPageSize));
        setActivePage(1);
        searchERA(newPageSize, 'PageLimit');
    };

    // Check all ERAs 
    function onSelectAll(e) {
        const selected = e.target.checked;
        const tableBodyData = ERAPostingTableDataSearch.tableBodyData;
        const temp = selected ? eraList.map(item => item.id) : [];

        for (const row of tableBodyData) {
            const index = row.findIndex(colItem => colItem.type === 'checkbox');
            row[index].value = selected;
        }

        setIsChecked(!isChecked);
        setSelectAll(selected);
        setSelectedEraList(temp);
    }

    /**
     * Get ERA Header Details
     * @required id payment /era header_pk
     */
    function getERASummary(id, appliedStatus) {
        if (appliedStatus === 2) {
            setIsApplied(true);
        }
        else {
            setIsApplied(false);
        }
        setEraAppliedStatus(appliedStatus);

        setShowLoadingBar(true);
        setReviewKey(true);
        setSelectedPaymentPK(id);
        setProcedureData([]);
        const era_adj_result = service.EraAdjustmentType(id)
        let tempPayAdj = []
        era_adj_result.then(response => {
            for (let item in response.data) {
                tempPayAdj.push(response.data[item])
            }
            setMultiPayAdjType(tempPayAdj)
        });
        const header_result = service.GetHeader(id);
        header_result.then(response => {
            const data = response.data;
            setEraOriginalData(data);

            // Extract payer zip details
            let payer_zip_details = '';
            if (data.payer_info && data.payer_info.payer_city) payer_zip_details += `${data.payer_info.payer_city} `;
            if (data.payer_info && data.payer_info.payer_state) payer_zip_details += `${data.payer_info.payer_state} `;
            if (data.payer_info && data.payer_info.payer_zip) payer_zip_details += data.payer_info.payer_zip;

            // Extract payee zip details
            let payee_zip_details = '';
            if (data.payee_info && data.payee_info.payee_city) payee_zip_details += `${data.payee_info.payee_city} `;
            if (data.payee_info && data.payee_info.payee_state) payee_zip_details += `${data.payee_info.payee_state} `;
            if (data.payee_info && data.payee_info.payee_zip) payee_zip_details += data.payee_info.payee_zip.toString().substring(0, 5);

            setEditEraVariables({
                ...editEraVariables, "payer_name": data.payer_info.payer_name,
                "payer_address": data.payer_info.payer_address, "payer_address2": data.payer_info.payer_address2,
                "payer_zip_details": payer_zip_details,
                "payee_name": data.payee_info.payee_name, "payee_address": data.payee_info.payee_address,
                "payee_address2": data.payee_info.payee_address2, "payee_zip_details": payee_zip_details,
                "eft": data.payment_reference_num, "ein": data.ein,
                "check_date": data.payment_date ? format(new Date(data.payment_date), DEFAULT_DATE_FORMAT) : '',
                "check_amount": data.total_check_amount, "production_date": data.era_received_date ? format(new Date(data.era_received_date), DEFAULT_DATE_FORMAT) : '',
                "applied": data.applied ? data.applied : "", "unapplied": data.unapplied ? data.unapplied : "",
            });
            setInsurancePaymentVariables({
                ...insurancePaymentVariables,
                "era_payer": data.payer_info.id,
                "era_payer_name": data.payer_info.payer_name,
                "payment_date": data.payment_date ? format(new Date(data.payment_date), "yyyy-MM-dd") : null,
                "reference_check_number": data.payment_reference_num,
                "amount": data.total_check_amount,
                "payment_mode": 1, "card_type": null,
                "level_adjustments": 0, "adjustment_type": [],
                "era_level_adjustments": data.era_level_adjustments, "era_adjustment_type": data.era_adjustment_type,
                "note": "", "practice": practicePK

            });
            setPaymentAdjustmentAmount(data.era_level_adjustments);
            setPayAdjTypeArr(data.era_adjustment_type ? data.era_adjustment_type : []);
            let tempPayAdjType = []
            if (tempPayAdj?.length > 0 && data?.era_adjustment_type?.length > 0) {
                tempPayAdj.forEach(item => {
                    if (data.era_adjustment_type.includes(item.name)) {
                        tempPayAdjType.push(item.id)
                    }
                });
            }
            setPaymentAdjustmentType(tempPayAdjType);
        });

        let qry = 'header_details_pk=' + id
        const result = service.GetClaimDataSummary(qry)
        result.then(response => {
            if (response.data) {
                setReviewData(response.data.map((item) => {
                    return {
                        ...item,
                        claimId: item.claim_pk,
                        is_reviewed: item.is_reviewed ? item.is_reviewed : false,
                        checked: item.is_reviewed ? item.is_reviewed : false,
                    }
                }))
            }
            setShowLoadingBar(false);
            //update parent checkbox
            const allChecked = response.data.every(item => item.is_reviewed);
            setIsReviewAll(allChecked);
        })
    }

    /******************************************************/
    /* @description GET PROCEDURE LEVEL ITEMS FROM AN ERA'S CLAIM
     * @param {*} id // ERA Header Id
     * @param {*} claim_id // claim_pk of the clicked claim item within the era
     * @param {*} manuallyAddedFlag // is this claim manually added 
     * @param {*} newClaimAdded // is this a new manually added claim
     * @description newClaimAdded argument is needed to forcefully make the user to save the procedure row items of a manually added claim so it can be sent to backend
     */
    function reviewClaim(id, claim_id, manuallyAddedFlag, newClaimAdded, isAllProcedureApplied) {
        setShowLoadingBar(true);
        setShowDetailsModal(true);
        setLastOpenedClaimRowItem(claim_id);
        setIsNewClaim(newClaimAdded);

        if (isAllProcedureApplied) {
            setIsAllProcedureApplied(true);
        } else {
            setIsAllProcedureApplied(false);
        }

        let tempOriginalResponsibility = []

        // Check if the below is a manually added claim
        // const isClaimManuallyAdded = reviewData.some(item => item.is_manually_added === true && item.claim_pk === claim_id);
        // If the claims is not a manually added one or it is manually added but it has been posted already then call the api if block  (clearing-house/era-payment-review/)
        // Else it should call the another api in the else block to get the procedure level data (clearing-house/era-payment-claim-procedures)
        if (!newClaimAdded) {
            const getAdjustmentData = (adjustments) => {
                return adjustments?.length ? adjustments.map(item => ({ label: item.code, amount: item.amount, id: item.id })) : [];
            }
            // Adjust the query to differentiate to get the procedures of the applied ones and non applied
            let qry = 'header_details_pk=' + id + "&claim_id=" + JSON.stringify([claim_id]);
            const result = service.GetClaimData(qry)
            result.then(async response => {
                try {
                    // Temporary variable to hold the procedureItems
                    let tempProcedureData = [];
                    if (response?.data && response.data.length) {
                        response.data.forEach((item) => {
                            let adjustment_code2_data = getAdjustmentData(item.adjustments?.adjustment2);
                            let adjustment_code1_data = getAdjustmentData(item.adjustments?.adjustment1);

                            if (Object.prototype.hasOwnProperty.call(editedProcedureData, claim_id + "|" + item.cpt_code)) {
                                tempProcedureData.push(editedProcedureData[claim_id + "|" + item.cpt_code])
                            } else {
                                
                                tempProcedureData.push({
                                    'id': item.id,
                                    'claimId': claim_id, // for frontend use
                                    'payment': item.payment_pk, 'claim': item.claim_pk, 'claim_custom_id': item.claim_id, 'cpt_code': item.cpt_code, 'patient_id': item.patient_id,
                                    'allowed_amount': item.allowed_amount ? item.allowed_amount : 0, 'paid_amount': item.paid_amount ? item.paid_amount : 0, 'adjustment_amount1': item.adj_amt_1 ? item.adj_amt_1 : 0, 'adjustment_code1': item.adj_code_1_id ? item.adj_code_1_id : '',
                                    'adjustment_amount2': item.adj_amt_2 ? item.adj_amt_2 : 0, 'adjustment_code2': item.adj_code_2_id ? item.adj_code_2_id : '', 'opening_balance': item.opening_balance ? Number(item.opening_balance).toFixed(2) : 0,
                                    'closing_balance': item.closing_balance ? item.closing_balance : 0,
                                    'charges': item.cpt_charge, 'responsibility_type': item?.next_responsibility?.id, 'next_responsibility': item?.next_responsibility?.id, 'pay_procedure_status': 0, 'adjustment_code2_data': adjustment_code2_data,
                                    'adjustment_code1_data': adjustment_code1_data, 'procedure': item.procedure_pk, 'exists_in_sys': item.exists_in_sys, 'cpt_not_found': item.cpt_not_found,
                                    'save_procedure_id': item.save_procedure_id, 'date_of_service': item.date_of_service, 'receipts': Number(item.receipts).toFixed(2),
                                    'selectedRemarkCodes': item.selected_remark_codes.map(currentItem => { return { ...currentItem, name: currentItem.label } }),
                                    'remark_codes': item.selected_remark_codes.map(currentItem => { return currentItem.id}),
                                    'copay': item.copay, 'coInsurance': item.co_insurance, 'deductible': item.deductibles,
                                    'is_applied': item?.is_applied,
                                    'claim_status_id': item.claim_status_id, "claim_sub_status_id": item.claim_sub_status_id,
                                    "cpt_mod_code": item.cpt_mod_code, "cpt_mod_code2": item.cpt_mod_code2, "cpt_mod_code3": item.cpt_mod_code3, "cpt_mod_code4": item.cpt_mod_code4,
                                    "current_responsibility": item.current_responsibility,
                                    "is_reviewed": item.is_reviewed ? item.is_reviewed : false,
                                    "checked": item.is_reviewed ? item.is_reviewed : false,
                                })
                            }

                            if (!tempOriginalResponsibility.find(item => item.patientPk == item.patient_id)) {
                                tempOriginalResponsibility.push({
                                    patient_pk: item.patient_id,
                                    responsibility: item?.next_responsibility?.id
                                })
                            }

                        });
                    } 
                    else {
                        throw new Error;
                    }
                    setProcedureData(tempProcedureData);
                    // Storing available responsiblity types available in the state so it can be utilized to change the responsiblity when user changes the amount
                    setResponsibilityTypes(response.data[0]?.responsibilites || RESPONSIBILITY_TYPES);
                    setResponsibilityList([{
                        patientPk: response.data[0].patient_id,
                        flag: true,
                        responsibilityList: response.data[0]?.responsibilites
                    }])

                    // When every fetching any calim's procedure level items it original data should be stored to the indexed db so it can be utilized to reset each procedure row 
                    // But before storing it first Check if this claim's Procedure Level data already there in the indexed db else store it
                    const existingData = await get(`${claim_id}`);
                    if (existingData === undefined){
                    await set(`${claim_id}`, tempProcedureData);
                    }   
                    setShowLoadingBar(false);
                    setSelectedClaimID(claim_id)
                } catch (error) {
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.Un_expected_error"));
                    setShowLoadingBar(false);
                    setShowDetailsModal(false);
                }
            });
        } else
        {
            const getAdjustmentData = (adjustments) => {
                return adjustments?.length ? adjustments.map(item => ({ label: item.code, amount: item.amount, id: item.id })) : [];
            }

            let query = '?claim_id=' + claim_id;
            if (isApplied) query += '&is_applied=true'

            const result = service.GetProcedureDataForManuallyAdded(query);
            setShowLoadingBar(true);
            setShowDetailsModal(true);
            setIsNewClaim(true);
            result.then(async response => {
                try {
                    let tempProcedureData = [];
                    if (response?.data?.procedure_list?.length) {
                        response.data.procedure_list.forEach((item) => {
                            let adjustment_code2_data = getAdjustmentData(item.adjustments?.adjustment2);
                            let adjustment_code1_data = getAdjustmentData(item.adjustments?.adjustment1);

                            if (Object.prototype.hasOwnProperty.call(editedProcedureData, claim_id + "|" + item.cpt_code)) {
                                const editedItem = editedProcedureData[claim_id + "|" + item.cpt_code];
                                editedItem.newClaimAdded = Boolean(newClaimAdded);
                                tempProcedureData.push(editedItem);
                            } else {
                                tempProcedureData.push({
                                    'id': item.id,
                                    'claimId': claim_id,
                                    'payment': item.payment_pk,
                                    'claim': item.claim_pk,
                                    'claim_custom_id': item.claim_id,
                                    'cpt_code': item.cpt_code,
                                    'patient_id': item.patient_id,
                                    'allowed_amount': item.allowed_amount ?? 0,
                                    'paid_amount': item.paid_amount ?? 0,
                                    'adjustment_amount1': item.adj_amt_1 ?? 0,
                                    'adjustment_code1': item.adj_code_1_id ?? '',
                                    'adjustment_amount2': item.adj_amt_2 ?? 0,
                                    'adjustment_code2': item.adj_code_2_id ?? '',
                                    'opening_balance': item.opening_balance ? Number(item.opening_balance).toFixed(2) : 0,
                                    'closing_balance': item.closing_balance ?? 0,
                                    'charges': item.cpt_charge,
                                    'responsibility_type': item?.next_responsibility?.id,
                                    'next_responsibility': item?.next_responsibility?.id,
                                    'pay_procedure_status': 0,
                                    'adjustment_code2_data': adjustment_code2_data,
                                    'adjustment_code1_data': adjustment_code1_data,
                                    'procedure': item.procedure_pk,
                                    'exists_in_sys': item.exists_in_sys,
                                    'cpt_not_found': item.cpt_not_found,
                                    "is_reviewed": item.is_reviewed ? item.is_reviewed : false,
                                    "checked": item.is_reviewed ? item.is_reviewed : false,
                                    'save_procedure_id': item.save_procedure_id,
                                    'date_of_service': item.date_of_service,
                                    'receipts': Number(item.receipts).toFixed(2),
                                    'selectedRemarkCodes': item.selected_remark_codes || [],
                                    'remark_codes': item.selected_remark_codes ?  item.selected_remark_codes.map(currentItem => { return currentItem.id}):[],
                                    'copay': item.copay,
                                    'coInsurance': item.co_insurance,
                                    'deductible': item.deductibles,
                                    'newClaimAdded': Boolean(newClaimAdded),
                                    'is_manually_added': Boolean(item.is_manually_added),
                                    'claim_status_id': item.claim_status_id || null,
                                    'claim_sub_status_id': item.claim_sub_status_id || null,
                                    'cpt_mod_code': item.cpt_mod_code || null,
                                    'cpt_mod_code2': item.cpt_mod_code2 || null,
                                    'cpt_mod_code3': item.cpt_mod_code3 || null,
                                    'cpt_mod_code4': item.cpt_mod_code4 || null,
                                    'current_responsibility': item.current_responsibility

                                })
                            }
                            if (!tempOriginalResponsibility.find(item => item.patientPk == item.patient_id)) {
                                tempOriginalResponsibility.push({
                                    patient_pk: item.patient_id,
                                    responsibility: item?.next_responsibility?.id
                                })
                            }
                        });
                    } else {
                        handleErrorResponse(claim_id, response);
                        return;
                    }
                    setProcedureData(tempProcedureData);
                    setResponsibilityList([{
                        patientPk: response.data.procedure_list[0].patient_id,
                        flag: true,
                        responsibilityList: response.data.procedure_list[0]?.responsibilites
                    }])
                    setResponsibilityTypes(response.data?.procedure_list[0]?.responsibilites || RESPONSIBILITY_TYPES);
                    const existingData = await get(`${claim_id}`);
                    if (existingData === undefined){
                        await set(`${claim_id}`, tempProcedureData);
                    } 
                    setShowLoadingBar(false);
                    setSelectedClaimID(claim_id)
                } catch (error) {
                    handleErrorResponse(claim_id);
                    return;
                }
            });
        }

        setOriginalResponsibility(tempOriginalResponsibility)
    }
    function handleErrorResponse(claim_id, response) {
        if (response?.data?.code === 404 || response?.status === 404) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.claim_not_found"));
        } else {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.error_ERA"));
        }
        setShowLoadingBar(false);
        // When error occurres during the api call of getting procedure items of a manually added claim, that claim itself should be removed from the table (state: reviewClaim) 
        if (!isApplied) {
            setReviewData((prevReviewData) => {
                return prevReviewData.filter(item => item.claimId !== claim_id);
            });
        }
    }
    /******************************************************/

    function onOpenFile() {
        document.getElementById('file_era').click();
    }
    /**function ivoke when cancel or back button click */
    function backToReview() {
        let claimids = getSelectedClaimIDs(reviewData);
        if (claimids.length > 0) {
            setConfirmationModal(true)
        } else {
            setSearchKey(true);
            setReviewKey(false);
        }
    }

    const onAlertOk = () => {
        setConfirmationModal(false)
    }

    /**function to go back to ERA table */
    const goBack = () => {
        setSearchKey(true);
        setReviewKey(false);
        setConfirmationModal(false)
    }



    function onHandleUpload(e) {
        const file = e.target.files[0];
        if (file.type === "application/pdf") {
            showNotifyWindow('show', 'error', i18n.t("validations.errors.uploadError"));
        }
        else if (file.type === "text/plain" || file.name.endsWith(".835") || file.name.endsWith(".edi")) {
            let name = e.target.name;
            let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
            const data = new FormData();
            data.append('era_file', value);
            data.append('practice_pk', practicePK);
            setShowLoadingBar(true);
            const result = service.UploadERA(data);
            document.getElementById(name).value = '';

            const handleResponse = (response) => {
                setShowLoadingBar(false);
                if (response.data.status_code === 400)
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.commonError"))
                if (response?.data?.message == 'era_not_belongs_to_this_practice')
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.era_not_belongs_to_this_practice"));
                if (response?.data?.message == 'no_file_records')
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.not_file_records"));
                if (response.data?.message === 'not_found')
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.not_match_claim_procedure"));
                if (response.data?.msg == 'not_835_file')
                    showNotifyWindow('show', 'error', response.data.msg);
                if (response.data.msg === 'ERA file already posted.')
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.alreadyUploaded"));
                if (response.data.error_message == 'data_parsing_failed' || response.data.error_message == "unconverted data remains: 4")
                    showNotifyWindow('show', 'error', i18n.t("errorMessages.parsingFailure"));
                if (response.status === 500)
                    showNotifyWindow('show', 'error', response.statusText);
                if (response.status === 200)
                    showNotifyWindow('show', 'success', i18n.t("successMessages.success_message"));
                if(response.status==409)
                    showNotifyWindow('show', 'error', i18n.t("payments.eraPage.duplicateReference"));
            }

            result.then(response => {
                handleResponse(response);
            }).catch((error) => {
                handleResponse(error);
            })
        }
    }

    /**
 * api to call make selected claim mark as reviewed
 */
    const MarkAsReviewed = async () => {
        setShowLoadingBar(true);
        let claimids = getSelectedClaimIDs(reviewData);
        if (claimids.length>0) {
            const result = await service.MakeAsReviewed(selectedPaymentPK, { claimids: claimids })
            if (result.status == 200) {
                showNotifyWindow("show", "success", "Selected Claim IDS marked as reviewed");
                lastOpenedEraRowItem
                let qry = 'header_details_pk=' + lastOpenedEraRowItem
                const result = service.GetClaimDataSummary(qry)
                result.then(response => {
                    if (response.data) {
                        setShowLoadingBar(false);
                        setReviewData(response.data.map((item) => {
                            return {
                                ...item,
                                claimId: item.claim_pk,
                                is_reviewed: item.is_reviewed ? item.is_reviewed : false,
                                checked: item.is_reviewed ? item.is_reviewed : false,
                            }
                        }))
                    }
                })
            } else {
                setShowLoadingBar(false);
                if (result.status == 404) {
                    showNotifyWindow("show", "error", "Something went wrong please try again.");
                }
                if (result.status == 400) {
                    showNotifyWindow("show", "error", "No Claim IDs Provided.");
                }
            }
        } else if (claimids.length === 0 && reviewData.length>0) {
            const isAnyClaimChecked = reviewData.some((item) => item.checked === false);
            if (isAnyClaimChecked) {
                setShowLoadingBar(false);
                showNotifyWindow("show", "error", "Please select or check at least one claim.");
            } else {
                setShowLoadingBar(false);
                showNotifyWindow("show", "error", "All the claims are reviewed.");
            }
        }else{
            setShowLoadingBar(false);
            showNotifyWindow("show", "error", "No claims found to review");
        }
    }


    const getSelectedClaimIDs = (data) => {
        let ids = [];
        if (data.length > 0) {
            data.forEach(ele => {
                if (ele.checked === true && !ele.is_reviewed) {
                    ids.push(ele.claim_pk);
                }
            });
        }
        return ids;
    };

    /**
     * @description To be called on apply btn hit, to post the era
     * @method API Method POST
     * @requires {"practice_pk": 1, "header_pk": 136, "procedures":[], "current_practice_id":1} to be passed to the api
     * @description "procedures":[] is an array of edited and manually added claim line item objects
     */
    async function onApplyPayment() {
        setShowLoadingBar(true);
        const header_pk = String(selectedPaymentPK);
        const practice_pk = getStorage('practice');
        const reference_check_number = insurancePaymentVariables?.reference_check_number ?? "";

        try {
            const response = await service.SaveInsurancePaymentProceduresFromERAPosting({ header_pk, practice_pk, reference_check_number });
            if (response.data?.code === 200 && response.data.msg === "success") {
                showNotifyWindow('show', 'success', i18n.t("successMessages.ERA_post_success"));

                if (response.data?.is_applied == 2) {
                    setIsApplied(true);
                    setEraAppliedStatus(response.data?.is_applied)
                } else {
                    setIsApplied(false);
                    setEraAppliedStatus(response.data?.is_applied)
                }

                setSearchKey(true);
                setReviewKey(false);
                searchERA();

            } else if (response.data?.code === 404 && response.data?.msg === "transaction_set_not_found") {
                showNotifyWindow('show', 'error', i18n.t("errorMessages.not_find_error"));

            } else if (response.data?.code === 400 || response.data?.status_code === 400) {
                switch (response?.data?.msg || response?.data?.error_message) {
                    case 'transaction_applied':
                    case 'provider_failed':
                    case 'header_details_exists':
                        showNotifyWindow('show', 'error', i18n.t("errorMessages.already_exist_era"));
                        break;
                    case 'procedure_not_found':
                    case 'claim_not_found':
                        showNotifyWindow('show', 'error', i18n.t("errorMessages.not_match_claim_procedure"));
                        break;
                    case 'era_payer_not_found':
                    case 'payer_not_found':
                        showNotifyWindow('show', 'error', i18n.t("errorMessages.mismatch_payer"));
                        break;

                    default:
                        showNotifyWindow('show', 'error', i18n.t("errorMessages.un_expected_auto_posting_error"));
                        break;
                }
                if (response.data?.message === 'invalid_responsibility_type') {
                    showNotifyWindow("show", "error", `Claim status selected not matching with the Priority on Procedure ${response.data?.data?.cpt ?? ""}`);
                }
                if (response.data?.message === 'cpt_or_claim_status_missing') {
                    showNotifyWindow("show", "error", `Procedure or the claim status is missing in a procedure row.`);
                }
            } else if (response.status === 404 && response.data?.message === "not_found") {
                showNotifyWindow('show', 'error', i18n.t("errorMessages.not_match_claim_procedure"));
            } else if (response?.data?.message === "transaction_applied") {
                showNotifyWindow('show', 'error', i18n.t("errorMessages.era_applied"));
            } else if (response?.data?.error_exception === "ValidationError") {
                showNotifyWindow('show', 'error', i18n.t("errorMessages.input_validation"));
            } else if(response.status==409){
                showNotifyWindow('show', 'error', i18n.t("payments.eraPage.duplicateReference"));
            }else {
                showNotifyWindow('show', 'error', i18n.t("errorMessages.auto_posting_era"));
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t("errorMessages.auto_posting_era"));
        } finally {
            setShowLoadingBar(false);
        }
    }

    function showEditModalWindow() {
        setHeader(i18n.t('payments.post_payments.editPayment'));
        setEditPayerName(editEraVariables.payer_name);
        setEditEFT(editEraVariables.eft);
        setEditCheckAmount(editEraVariables.check_amount);
        setEditCheckDate(editEraVariables.check_date);
        setShowEditERAModal(true);
    }

    function onSaveFormData(e) {
        e.preventDefault();
        let temp_unapplied = editCheckAmount - paymentAdjustmentAmount;
        setEditEraVariables({
            ...editEraVariables, payer_name: editPayerName, eft: editEFT,
            check_amount: editCheckAmount, check_date: editCheckDate,
            unapplied: temp_unapplied
        })
        let tempPayAdj = []
        if (paymentAdjustmentType.length > 0) {
            multiPayAdjType.map((item) => {
                if (paymentAdjustmentType.indexOf(item.id) !== -1) {
                    tempPayAdj.push(item.name)
                }
            })
        }
        setPayAdjTypeArr(tempPayAdj)
        setInsurancePaymentVariables({
            ...insurancePaymentVariables,
            "era_level_adjustments": paymentAdjustmentAmount,
            "era_adjustment_type": tempPayAdj,
            "amount": editCheckAmount,
            "payment_date": editCheckDate


        });
        setShowEditERAModal(false);
    }

    function onHandleERAPayment(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'payer_name') {
            setEditPayerName(value);
        } else if (name === 'eft') {
            setEditEFT(value);
        } else if (name === 'check_amount') {
            setEditCheckAmount(value);
        } else if (name === 'paymentAdjustmentAmount') {
            let newValue = value.trim().replace(/[^0-9.-]/g, '')
            setPaymentAdjustmentAmount(newValue);
        }
    }

    function onHandleERACheckDateChange(selected) {
        setEditCheckDate(selected)
    }

    function onSearchAdjustmentReason_1_code(query) {
        if (query)
            query += '&practice_pk=' + practicePK;
        else
            query = 'practice_pk=' + practicePK;
        const result = service.GetListAdjustmentReasonCode(0, 0, query, 'auto');
        result.then(response => {
            setAdjustment_1_codes_list(response?.data || []);
        });
    }

    function onSearchAdjustmentReason_2_code(query) {
        if (query)
            query += '&practice_pk=' + practicePK;
        else
            query = 'practice_pk=' + practicePK;
        const result = service.GetListAdjustmentReasonCode(0, 0, query, 'auto');
        result.then(response => {
            setAdjustment_2_codes_list(response?.data || []);
        });
    }

    function calculateTotals(data) {
        let totals = {
            allowed_amount: 0,
            paid_amount: 0,
            closing_balance: 0,
            adjustment_code2_data: 0,
            adjustment_code1_data: 0
        };

        data.forEach(item => {
            totals.allowed_amount += parseFloat(item.allowed_amount || 0);
            totals.paid_amount += parseFloat(item.paid_amount || 0);
            totals.closing_balance += parseFloat(item.closing_balance || 0);
            totals.adjustment_code2_data += item.adjustment_code2_data.reduce((total, adj) => total + parseFloat(adj.amount || 0), 0);
            totals.adjustment_code1_data += item.adjustment_code1_data.reduce((total, adj) => total + parseFloat(adj.amount || 0), 0);
        });

        return totals;
    }

    // Save Procedure level payment details
    function saveEditProcedures(responseData) {
        let isInvalid = false;

        if (!isInvalid) {
            setShowLoadingBar(true);
            if (procedureData.length > 0) {
                let tempEditedProcedureData = editedProcedureData;

                procedureData.map((item) => {
                    tempEditedProcedureData[responseData[0]?.claim_pk + '|' + item.cpt_code] = {
                        ...item,
                        claim_pk: responseData[0]?.claim_pk,
                        claimId: responseData[0]?.claim_pk
                    }
                });
                setEditedProcedureData(tempEditedProcedureData);

                // Update the total allowed, paid, adjustment1 & 2, closingBalances
                const updatedTotals = calculateTotals(procedureData);

                let tempReviewData = reviewData;
                tempReviewData.forEach((item) => {
                    if (item.claim_pk === selectedClaimID) {
                        item.updatedTotals = updatedTotals;
                        item.isModified = true;
                        item.warning = false;
                        item.checked = true;
                        item.claim_pk = responseData[0]?.claim_pk;
                        item.is_reviewed = true;        
                    }
                });

                setReviewData(tempReviewData);

            // Check if all items are reviewed to update the parent checkbox
            const allChecked = tempReviewData.every(item => item.checked);
            setIsReviewAll(allChecked);
            }
            setShowLoadingBar(false);
            setShowDetailsModal(false);
        }
    }

    useEffect(() => {
        setEditedProcedureData({})
    }, [reviewKey])

    function reverseAll() {
        let appliedList = []
        eraList.map((item) => {
            if (item.applied_status === 2 && selectedEraList.indexOf(item.id) !== -1) {
                appliedList.push(item.id)
            }
        })
        if (appliedList.length > 0) {
            setShowLoadingBar(true);
            const result = service.ReversePayment({ 'header_details_pks': appliedList });
            result.then(() => {
                setShowLoadingBar(false);
                setSelectedEraList([]);
                setSelectAll(false)
                searchERA();
            })
        }
    }

    function exportERAList() {
        if (selectedEraList.length > 0) {
            setShowLoadingBar(true);
            const result = service.ExportERAList({ 'header_details_pks': selectedEraList })
            result.then(response => {
                setShowLoadingBar(false);
                setSelectedEraList([]);
                setSelectAll(false)
                fileDownload(response.data, response.headers['content-disposition']);
                if (response.status === 200) {
                    onSelectAll({ target: { checked: false } })
                }
            });
        }
    }
    function applyAll() {
        let unappliedList = []
        eraList.map((item) => {
            if (item.applied_status === 1 && selectedEraList.indexOf(item.id) !== -1) {
                unappliedList.push(item.id)
            }
        })
        if (unappliedList.length > 0) {
            setShowLoadingBar(true);
            const result = service.ApplyAll({ 'selectedIds': unappliedList })
            result.then(() => {
                setShowLoadingBar(false);
                showNotifyWindow('show', 'success', i18n.t('payments.eraPage.selectERAApplied'));
                searchERA();
            });
        }

    }
    function addRemoveFromSelectedTab(item) {
        const result = serviceClaim.AddRemoveSelectedTab(item);
        result.then(() => {
            // getSelectedClaims();
            // setSelectedClaimType("");
        });
    }
    function onPatientNameClick(patientID) {
        const result = serviceClaim.GetSelectedTabs('patients', getStorage("practice"));
        result.then(response => {
            let openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks.filter(item => parseInt(item, 10) > 0) : [];
            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_PATIENTS && !openedPKs.includes(patientID)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_patient_tabs'));
            }
            else {
                let item = { pk: patientID, type: 'patients', action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);
                setRedirectToPatient(true);
            }
        });
    }


    function MaterialMultiSelectHandle(e) {
        let value = e.target.value;
        setPaymentAdjustmentType(value);
        let tempAmnt = 0
        multiPayAdjType.map((item) => {
            if (value.indexOf(item.id) !== -1) {
                tempAmnt = tempAmnt + item.amount;
            }
        });
        setPaymentAdjustmentAmount(tempAmnt);
    }
    function applyUnapplyAll() {
        let applied_status_1 = ''
        let applied_status_2 = ''
        eraList.map((item) => {
            if (item.applied_status === 2 && selectedEraList.indexOf(item.id) !== -1) {
                applied_status_2 = item.applied_status
            }
            else if (item.applied_status === 1 && selectedEraList.indexOf(item.id) !== -1) {
                applied_status_1 = item.applied_status
            }
        })
        if (applied_status_1 && !applied_status_2) {
            applyAll();
        } else if (applied_status_2 && !applied_status_1) {
            reverseAll();
        }
    }

    function onSearchERAPayer(query) {
        // to list era payer from era posting - API call
        const result = service.ERAPayer(query);
        result.then((response) => {
            let data = [];
            if (response.data && response.data.length > 0) {
                response.data.forEach((item) => {
                    data.push({ 'id': item.Id, 'name': item.name })
                })
            }
            setEraPayerList(data);
        })
    }


    function getAdjustmentTypes() {
        const result = service.GetListAdjustmentTypes(
            DEFAULT_PAGING_SIZE,
            0,
            "dropdown"
        );
        result.then(() => {
        });
    }

    if (redirectToPatient) {
        return <Redirect to={{ pathname: ROUTE_PATIENTS_LIST }} />;
    }

    async function openLink(id, isApplied) {
        let item = eraList.find(obj => obj.id == id);
        setLastOpenedEraRowItem(id);
        // When user goes back from the ERA Review window, the indexed db must be cleaned
        // Please handle the functionality appropriately when removing the indexed db clear(); 
        try {
            await clear();
        } catch (error) {
            console.error('Failed to clear data from IndexedDB', error);
        }
        getERASummary(id, isApplied === true ? 2 : item?.applied_status);
    }

    function onClickGridCheckBox(e, clickedFrom, id) {
        if (clickedFrom == 'data') {
            onChecked(e, id);
        }
        else if (clickedFrom == 'header') {
            onSelectAll(e);
        }
    }

    return (
        <>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className={(searchKey && !reviewKey) ? 'col-md-12 border-radius-8' : 'col-md-12  border-radius-8'} >
                {
                    !reviewKey && searchKey &&
                    <div className={'row margin-left30 mb-3'}>
                        <Link to="#" className="link dictionaries-back" style={{ color: '#212121' }} onClick={showSearch}>
                            <ArrowBackIosIcon style={{ fontSize: 12, marginBottom: 3 }} />
                            <span>{i18n.t("payments.eraPage.backToSearch")}</span>
                        </Link>
                    </div>
                }

                {reviewKey && <div className={'row margin-left30 mb-3'}>
                    <Link to="#" className="link dictionaries-back" style={{ color: '#212121' }} onClick={backToReview}>
                        <ArrowBackIosIcon style={{ fontSize: 12, marginBottom: 3 }} />
                        <span>{i18n.t("dictionariesPages.EraPosting")}</span></Link>
                </div>}
                <div className='box basic-info-padding bordering border-radius-8'>
                    {/* back button */}
                    {!searchKey &&
                        <div className='padding-top20 margin-right20'>
                            {checkPermission(permission_key_values_payments.payments_era_posting_add) &&
                                <div className="justify-right">
                                    <div className="hidden">
                                        <input type="file" id="file_era" name="file_era"
                                            accept=".835,text/plain,.edi"
                                            onChange={onHandleUpload} />
                                    </div>
                                    <CommonButton variant='contained' icon="upload" onClick={onOpenFile} label={i18n.t('payments.eraPage.upload_era')} />
                                </div>
                            }
                        </div>}
                    <Form autoComplete='off'>
                        <div className='margin-left20 margin-right20 padding-bottom10'>
                            {!searchKey &&
                                <div className=''>
                                    <div className='row'>
                                        <div className='col-3 form-group padding-top20'>
                                            <AsyncTypeInput
                                                id={"payer"}
                                                labelKey="name"
                                                label={i18n.t('payments.eraPage.payerName')}
                                                minLength={3}
                                                options={eraPayerList}
                                                onSearch={onSearchERAPayer}
                                                name={"payer"}
                                                clearFlag={clearFlag}
                                                onValueChange={(e) => onHandleChangeEraVariables(e, "payer")}
                                                selected={searchPayer}
                                            />
                                        </div>
                                        <div className='col-3 padding-top20'>
                                            <CalendarInput name="payment_date_from" id="payment_date_from" selected={eraVariables.payment_date_from}
                                                onValueChange={(e) => onHandleChangeEraDate(e, "payment_date_from")} label={i18n.t('payments.eraPage.paymentDateFrom')} />
                                        </div>
                                        <div className='col-3 padding-top20'>
                                            <CalendarInput name="payment_date_to" minDate={eraVariables.payment_date_from} id="payment_date_to" selected={eraVariables.payment_date_to}
                                                onValueChange={(e) => onHandleChangeEraDate(e, "payment_date_to")} label={i18n.t('payments.eraPage.paymentDateTo')} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <CalendarInput name="era_uploaded_date_from" id="era_uploaded_date_from" selected={eraVariables.era_uploaded_date_from}
                                                onValueChange={(e) => onHandleChangeEraDate(e, "era_uploaded_date_from")} label={i18n.t('payments.eraPage.eraUploadedDateFrom')} />
                                        </div>
                                        <div className='col-3'>
                                            <CalendarInput name="era_uploaded_date_to" minDate={eraVariables.era_uploaded_date_from} id="era_uploaded_date_to" selected={eraVariables.era_uploaded_date_to}
                                                onValueChange={(e) => onHandleChangeEraDate(e, "era_uploaded_date_to")} label={i18n.t('payments.eraPage.eraUploadedDateTo')} />
                                        </div>
                                        <div className='col-3'>
                                            <TextInput name='payment_reference' id="payment_reference" value={eraVariables.payment_reference} onValueChange={onHandleChangeEraVariables} label={i18n.t('payments.eraPage.payment_reference')} />
                                        </div>
                                        <div className='col-3'>
                                            <CurrencyInputs name='payment_amount' id="payment_amount" value={eraVariables.payment_amount} onValueChange={onHandleChangeEraVariables} label={i18n.t('payments.eraPage.payment_amount')} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <SelectInput data={[SELECT_OPTION_ALL, ...SHOW_TYPE]} id="show_type" selectOptionRemove={true} name='show_type' value={eraVariables.show_type} onValueChange={onHandleChangeEraVariables} label={i18n.t('payments.eraPage.show_type')} />
                                        </div>
                                        <div className='col-3'>
                                            <SelectInput data={ERA_PAYMENT_MODE} name='payment_mode' id="payment_mode" value={eraVariables.payment_mode} onValueChange={onHandleChangeEraVariables} label={i18n.t('payments.eraPage.payment_mode')} />
                                        </div>
                                        <div className='col padding-top25 justify-right'>
                                            <div className=''>
                                                <CommonButton noBorder={true} variant='outlined' onClick={resetERASearch} label={i18n.t('buttons.reset')} />
                                            </div>
                                            <div className='margin-left10'>
                                                <CommonButton variant="contained" onClick={() => { searchERA(1, 'search') }} label={i18n.t('payments.eraPage.search')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* ERA Search search table */}
                            {(searchKey && !reviewKey) &&
                                <ERASearchTable
                                    showSearch={showSearch}
                                    selectedEraList={selectedEraList}
                                    applyUnapplyAll={applyUnapplyAll}
                                    exportERAList={exportERAList}
                                    ERAPostingTableDataSearch={ERAPostingTableDataSearch}
                                    openLink={openLink}
                                    onClickGridCheckBox={onClickGridCheckBox}
                                    selectAll={selectAll}
                                    totalPage={totalPage}
                                    activePage={activePage}
                                    startIndex={startIndex}
                                    endIndex={endIndex}
                                    onPagePrevious={onPagePrevious}
                                    onPageUp={onPageUp}
                                    onPageNext={onPageNext}
                                    lastOpenedEraRowItem={lastOpenedEraRowItem}
                                    totalCount={totalCount}
                                    pageLimit={pageLimit}
                                    setPageLimit={setPageLimit}
                                    handlePageSizeChange={handlePageSizeChange}
                                    startItem={startItem}
                                    endItem={endItem}
                                />
                            }
                        </div>

                        {/* Open individual Payer/ Insurance ERA to apply on claim level */}
                        {reviewKey &&
                            <ERAApply
                                eraOriginalData={eraOriginalData}
                                showEditModalWindow={showEditModalWindow}
                                reviewData={reviewData}
                                reviewClaim={reviewClaim}
                                payAdjTypeArr={payAdjTypeArr}
                                paymentAdjustmentType={paymentAdjustmentType}
                                selectedPaymentPK={selectedPaymentPK}
                                onPatientNameClick={onPatientNameClick}
                                onApplyPayment={onApplyPayment}
                                isApplied={isApplied}
                                eraAppliedStatus={eraAppliedStatus}
                                isAllProcedureApplied={isAllProcedureApplied}
                                insurancePaymentVariables={insurancePaymentVariables}
                                backToReview={backToReview}
                                adjustment_1_codes_list={adjustment_1_codes_list}
                                onSearchAdjustmentReason_1_code={onSearchAdjustmentReason_1_code}
                                adjustment_2_codes_list={adjustment_2_codes_list}
                                onSearchAdjustmentReason_2_code={onSearchAdjustmentReason_2_code}
                                showDetailsModal={showDetailsModal}
                                setShowDetailsModal={setShowDetailsModal}
                                procedureData={procedureData}
                                saveEditProcedures={saveEditProcedures}
                                setReviewData={setReviewData}
                                setProcedureData={setProcedureData}
                                setManuallyAddedClaimIDs={setManuallyAddedClaimIDs}
                                manuallyAddedClaimIDs={manuallyAddedClaimIDs}
                                claimReviewedList={claimReviewedList}
                                selectedClaimID={selectedClaimID}
                                setSelectedClaimID={setSelectedClaimID}
                                setClaimReviewedList={setClaimReviewedList}
                                editedProcedureData={editedProcedureData}
                                setEditedProcedureData={setEditedProcedureData}
                                responsibilityTypes={responsibilityTypes}
                                responsibilityList={responsibilityList}
                                lastOpenedClaimRowItem={lastOpenedClaimRowItem}
                                originalResponsibility={originalResponsibility}
                                claimStatusList={claimStatusList}
                                claimSubStatusList={claimSubStatusList}
                                MarkAsReviewed={MarkAsReviewed}
                                isReviewAll={isReviewAll}
                                setIsReviewAll={setIsReviewAll}
                                isNewClaim={isNewClaim}
                            />
                        }
                    </Form>
                </div >
            </div >

            {/* Modal to Edit Payer ERA Details from the ERA Claims Table */}
            <CustomizedDialogs header={header} type="save" showModal={showEditERAModal} setShowModalWindow={setShowEditERAModal} >
                <Form id="form_dataEntry" autoComplete="off" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col-md-6">
                            <TextInput type="text" name="payer_name" placeholder={i18n.t("payments.eraPage.editEra.payerName")} value={editPayerName} onValueChange={onHandleERAPayment} label={i18n.t("payments.eraPage.editEra.payerName")} />
                        </div>
                        <div className="col-md-6">
                            <TextInput type="text" placeholder={i18n.t("payments.eraPage.editEra.eft")} name="eft" value={editEFT} onValueChange={onHandleERAPayment} label={i18n.t("payments.eraPage.editEra.eft")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <TextInput type="text" placeholder={i18n.t("payments.eraPage.editEra.checkAmount")} name="check_amount" value={editCheckAmount} onValueChange={onHandleERAPayment} label={i18n.t("payments.eraPage.editEra.checkAmount")} />
                        </div>
                        <div className="col-md-6">
                            <CalendarInput name="check_date" placeholder={i18n.t("payments.eraPage.editEra.checkDate")} selected={editCheckDate} onValueChange={onHandleERACheckDateChange} label={i18n.t("payments.eraPage.editEra.checkDate")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <MaterialMultiselect name={"paymentAdjustmentType"} value={paymentAdjustmentType} onValueChange={MaterialMultiSelectHandle}
                                options={multiPayAdjType} label={i18n.t("payments.eraPage.editEra.paymentLevelAdjustmentType")} />
                        </div>
                        <div className="col-md-6">
                            <TextInput type="text" placeholder={i18n.t("payments.eraPage.editEra.paymentLevelAdjustmentAmount")} name="paymentAdjustmentAmount" value={paymentAdjustmentAmount} onValueChange={onHandleERAPayment} label={i18n.t("payments.eraPage.editEra.paymentLevelAdjustmentAmount")} />
                        </div>
                    </div>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={confirmationModal}
                header={i18n.t("commons.alert")}
                alertOK={() => onAlertOk()}
                type="confirmation"
                goBack={() => goBack()}>
                <div className="display-grid">
                    Do you want to mark as reviewed selected claims?
                </div>
            </CustomizedSmallDialogs>
        </>
    )
}